import { Box, Grid } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Helmet } from 'react-helmet';
import * as GlobalComponents from '../../globalStyles';
import {
    BrandContainer,
    BrandLogoContainer,
    BrandLogoImg,
    BrandNameContainer,
    BrandNameText,
    BrandParagraph,
    BrandWrapper,
    LargeImg,
} from './styles';


export const AboutUsPage = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    return (
        <>
            <Box>
                <Helmet>
                    <title>
                        About The Best Premier Truck Shop In Georgia: Bedrock Flatbeds, OEM Takeoffs, and more
                    </title>
                    <meta
                        name="description"
                        content="Learn about Knollwood Tire & Wheel’s team and how we became one of the most successful truck shops in Georgia. Since DATE BEGAN, our focus has been to deliver an experience that goes above and beyond the parts that you buy, but the service you receive."
                    />
                    <meta
                        name="keywords"
                        content="Utility truck wheels and tires, flatbed truck installation, steel flatbeds, Bedrock flatbeds, oem wheels for sale, camlocker toolboxes, centennial tires, flat beds, utility truck accessories."
                    />
                </Helmet>
                <GlobalComponents.Container>
                    <GlobalComponents.GlobalH1>
                        Our History | Knollwood Tire & Wheel
                    </GlobalComponents.GlobalH1>
                    <GlobalComponents.MainParagraph>
                        Our journey with Knollwood Tire & Wheel started during our school days, selling used tires. In 2016, after a brief stint in banking, we committed ourselves to full-time tire sales out of a 600 sq ft shop. Our dedication led to rapid growth, expanding to multiple buildings.
                        <br />
                        <br />
                        Our first retail venture was in Broxton, where we repurposed a former Dollar General Building. We equipped it with lifts, tire and wheel services, and flatbed installations, catering to truck enthusiasts who appreciate authenticity. The need for more space led us to a 90,000 sq ft warehouse in Douglas. From here, we manage both retail and online orders.
                        <br />
                        <br />
                        At Knollwood Tire & Wheel, we prioritize authentic connections over transactions. We uphold values that resonate with hardworking individuals seeking reliable products. Our commitment to support, clarity, and respect defines our brand, echoing the values of those who value genuine experiences.
                        <br />
                        <br />
                        Our purpose is clear: delivering quality products and building lasting relationships. We're not just a tire and wheel business; we're a reflection of the blue-collar spirit – straightforward, authentic, and focused on what truly matters.
                    </GlobalComponents.MainParagraph>
                    <GlobalComponents.GlobalH2>
                        Our First Truck Shop | Douglas, Georgia
                    </GlobalComponents.GlobalH2>
                    <GlobalComponents.MainParagraph>
                        Established in Broxton in 2020, we later relocated in 2022 to accommodate our growth. Adapting swiftly, we efficiently utilized an 8000 sq ft space. Yet, as demand persisted and our ambition grew, it became evident that more space was imperative. Consequently, we made the strategic decision to relocate 10 miles south to the thriving town of Douglas.
                        <br />
                        <br />
                        Recognizing the need for optimized operations, we segregated our warehouse and retail functions into different locations. Presently, our retail-exclusive outlet sits prominently on the Douglas bypass, offering direct access for customers. Just a few blocks away, our expansive warehouse operates seamlessly, ensuring efficient management of inventory and online orders. At Knollwood Tire & Wheel, our focus remains simple – delivering quality, building connections, and catering to those who value straightforward, no-nonsense experiences.
                    </GlobalComponents.MainParagraph>
                    <GlobalComponents.GlobalH2>
                        What Makes Knollwood Tire & Wheel Different?
                    </GlobalComponents.GlobalH2>
                    <GlobalComponents.MainParagraph>
                        Knollwood Tire & Wheel speaks to the individual where their vehicle isn’t only a mode of transportation, but it’s part of their lifestyle. We understand a day’s hard work and want to ensure the parts we install are the same ones we use on our trucks. While we offer a wide range of products we want to work with those who value a firm handshake, the follow up of a phone call, and feeling like the relationship after a purchase is more important than the purchase itself.
                    </GlobalComponents.MainParagraph>
                    <GlobalComponents.GlobalH2>
                        Our Customer Reviews
                    </GlobalComponents.GlobalH2>
                    <GlobalComponents.MainParagraph>
                        At Knollwood Tire & Wheel, we take immense pride in our commitment to authenticity and respect. We're not just here to sell tires and wheels; we're here to make sure you get the right solution for your needs. The customer reviews for our services affirm this commitment. Our customers, most of whom belong to the hardworking class of individuals that build and modify trucks, appreciate our no-nonsense, down-to-earth approach.
                    </GlobalComponents.MainParagraph>
                    <GlobalComponents.GlobalH2>
                        Best Prices on Flatbeds, Bumpers and More
                    </GlobalComponents.GlobalH2>
                    <GlobalComponents.MainParagraph>
                        Outside of the service and priority we put on our customers to ensure their needs are met with each build we do, we also guarantee the lowest prices on the products we sell. This includes Camlocker Toolboxes, Bedrock Flatbeds, OEM Takeoffs and more. Should you find prices lower elsewhere at an authorized reseller, we will match that price. Build confidently with us at Knollwood Tire & Wheel.
                        <br />
                        <br />
                        Give us a call at <a href="tel:+19122600005">(912) 260-0005</a>  or email us at <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> for more information.
                    </GlobalComponents.MainParagraph>
                </GlobalComponents.Container>
            </Box>
        </>
    );
};
