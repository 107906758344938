import * as React from 'react';
import {
    ImageGalleryContainer,
    MainPhotoWrapper,
    MainPhoto,
    GalleryThumbsList,
    GalleryThumbContainer,
    GalleryThumb,
} from './styles';
import {useState} from 'react';

export interface I_galleryImage {
    focused: boolean;
    src: string;
}

export const ImageGallery: React.FC<{images: I_galleryImage[]}> = ({images}) => {
    const [stateImages, setState] = useState(images);

    const currentImage =
        stateImages.find((image) => {
            return image.focused;
        }) ?? null;

    const handleMouseEvent = (key: number) => {
        if (!stateImages[key].focused) {
            const newStateImages = stateImages.map((image, index) => ({
                ...image,
                focused: index === key,
            }));
            setState(newStateImages);
        }
    };

    return currentImage ? (
        <ImageGalleryContainer>
            <MainPhotoWrapper>
                <MainPhoto src={currentImage.src} alt={''} />
            </MainPhotoWrapper>
            {stateImages.length && (
                <GalleryThumbsList>
                    {stateImages.map((image, key) => {
                        return (
                            <GalleryThumbContainer key={key} onMouseEnter={() => handleMouseEvent(key)}>
                                <GalleryThumb src={image.src} />
                            </GalleryThumbContainer>
                        );
                    })}
                </GalleryThumbsList>
            )}
        </ImageGalleryContainer>
    ) : (
        <></>
    );
};
