import * as React from "react";
import { Helmet } from "react-helmet";
import * as GlobalComponents from "../../../globalStyles";
import { LargeImg } from "../../../common/components/Images";

export const SprayBedlinersPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Spray-On Bedliner Services | Warner Robins, Georgia</title>
        <meta
          name="description"
          content="The exclusive distributor of RhinoLiner Spray In Bedliners in Southeast Georgia | Same Day installation"
        />
        <meta
          name="keywords"
          content="same day bedliner, spray on bedliner, georgia bedliners, Bedrock flatbeds, oem wheels for sale, local spray on bedliner, RhinoLiner, flat beds, utility truck accessories"
        />
      </Helmet>
      <GlobalComponents.Container>
        <GlobalComponents.GlobalH1>
          Spray-On Bedliner Services
          <br />
          <GlobalComponents.GlobalH1Accent>
            Near Warner Robins, Georgia
          </GlobalComponents.GlobalH1Accent>
        </GlobalComponents.GlobalH1>
        <LargeImg>
          <img
            src="/assets/bedliner-header.jpg"
            alt="Pickup Truck bed with spray in bedliner"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          When it comes to protecting your truck bed, nothing beats the
          convenience and quality of spray-in bedliner. At Knollwood Tire &
          Wheel, we specialize in providing truck owners with the ideal solution
          for optimum utility and protection.
          <br />
          <br />
          Our spray-in bedliners are known for their quick-drying capabilities,
          allowing you to use your truck bed in no time. Whether you use your
          truck for work or play, our bedliners offer exceptional scratch
          resistance, ensuring that your truck bed stays in great condition for
          years to come.
          <br />
          <br />
          As an exclusive distributor of RhinoLiner spray-on bedliner in South
          Georgia, you can rest assured knowing your truck is in good hands.
          <br />
          <br />
          Give us a call for same-day installation:{" "}
          <a href={"tel:4783025064"}>478-302-5064</a>
          <br />
          <br />
          So if you're in the Middle Georgia area and need a quality bedliner
          for your truck, look no further than Knollwood Tire & Wheel. We're
          your trusted experts in truck accessories and bedliners, offering not
          only spray-on bedliner services but also a wide range of truck gear
          and accessories to meet your needs.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>
          RhinoLiner Services in Georgia
        </GlobalComponents.GlobalH2>
        <GlobalComponents.MainParagraph>
          When it comes to protecting your truck bed in Georgia, nothing beats
          the quality and durability of RhinoLiner spray-on bedliner services.
          At Knollwood Tire & Wheel, we are proud to offer this as an exclusive
          RhinoLiner distributor.
        </GlobalComponents.MainParagraph>
        <LargeImg>
          <img
            src="/assets/bedliner-1.jpg"
            alt="Pickup Truck bed with spray in bedliner, before and after"
          />
        </LargeImg>
        <GlobalComponents.GlobalH3>
          Spray On Bed Liner Protection - How It Works
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Your truck's bed is exposed to a lot of wear and tear, from hauling
          heavy loads to enduring the elements. That's why it's important to
          invest in a reliable bedliner that will provide ultimate protection
          and enhance the longevity of your truck. At Knollwood Tire & Wheel, we
          understand the importance of a durable bedliner that not only
          safeguards your truck but also adds a touch of style. That's why we
          offer top-of-the-line spray-in bedliner services in Georgia. Our
          expert applicators use industry-leading products like RhinoLiner to
          provide the perfect coating for your truck bed. But how does it work?
          <br />
          <br />
          RhinoLiner bedliners are made from a special blend of polymers that
          bond to your truck's bed, creating a tough and resilient protective
          layer. This coating has a wide range of benefits, including optimum
          utility, precise tolerances, and chemical resistance. Once applied,
          the RhinkLiner bedliner prevents scratches, dents, and corrosion
          caused by everyday use or event damage. It also serves as a barrier
          against air pollutants and UV rays, keeping your truck bed in top
          condition for years to come.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Lifetime Warranty for Truck Bed Spray Liners
        </GlobalComponents.GlobalH3>
        <LargeImg>
          <img
            src="/assets/bedliner-2.jpg"
            alt="Pickup Truck bed with spray in bedliner"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          At Knollwood Tire & Wheel, we understand the importance of protecting
          your truck bed. That's why we are proud to offer lifetime warranty for
          our truck bed liners.
          <br />
          <br />
          With our lifetime warranty, you can have peace of mind knowing that
          your truck bed is protected for the long haul. Our efficient
          installation process ensures that you can get back on the road the
          same day, with a truck bed that is ready for anything.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>CONTACT US</GlobalComponents.GlobalH2>
        <GlobalComponents.MainParagraph>
          For all your truck bed coating needs, you can easily reach us at
          Knollwood Tire & Wheel. Contact us today to schedule an appointment or
          to get more information about our services. Our friendly and
          knowledgeable staff are ready to assist you in finding the perfect
          solution for your truck bed protection.
          <br />
          <br />
          Here is our contact information:
          <br />
          <b>Knollwood Tire & Wheel</b>
          <br />
          <b>Address:</b> 2074 Watson Blvd, Warner Robins, GA 31093
          <br />
          <b>Telephone:</b> <a href={"tel:4783025064"}>478-302-5054</a>
        </GlobalComponents.MainParagraph>
      </GlobalComponents.Container>
    </>
  );
};
