import * as React from "react";
import { Helmet } from "react-helmet";
import * as GlobalComponents from "../../../globalStyles";
import {
  BrandContainer,
  BrandLogoContainer,
  BrandLogoImg,
  BrandNameContainer,
  BrandNameText,
  BrandParagraph,
  BrandWrapper,
  ProductComparisonContainer,
  ProductComparisonTable,
} from "../../../common/components/LandingPages";
import { LargeImg } from "../../../common/components/Images";
import { ProductAccordion } from "../../../common/components/LandingPages/ProductAccordion";

const sections = [
  {
    header: "Diamond Series Flatbeds",
    content: `<div class='accordion-img'><img src="/assets/diamond-flat.png"  alt="Pickup Truck with Bedrock Diamond Series Flatbed"/></div>Bedrock's Diamond Series Flatbeds are one of the highest quality flatbed options on the market today. These flatbeds are built to last with expert craftsmanship and innovative design.<br/><br/> 
The Diamond Series Flatbeds come with a variety of features, including a tread plate steel floor for added durability, stake pockets for securing cargo,  Optional components include trailer plugs and gussets, and underbody toolboxes for added storage. Hauling capabilities are maximized utilizing a rated gooseneck hitch and a receiver type bumper.<br/><br/> 
Additionally, the Diamond Series Flatbeds feature a headache rack for added protection, rear corners for improved visibility, and a tail board for easy loading and unloading. These flatbeds are equipped with a strong receiver type bumper and are finished with a high-quality powder coat paint for added protection against the elements.<br/><br/>
Whether you're in need of a flatbed for your com mon carrier truck, custom truck bed, the Diamond Series Flatbed by Bedrock is the industry’s flatbed truck body option on the market.  With the assurance of their quality guarantee, you can trust that Bedrock has your back for all your hauling needs.
`,
  },
  {
    header: "Granite Series Skirted Flatbeds",
    content: `<div class='accordion-img'><img src="/assets/granite-flat.png"  alt="Pickup Truck with Bedrock Granite Series Skirted Flatbed"/></div>The Granite Series Skirted Flatbed is one of the latest offerings from Bedrock Flatbeds. The unique feature of this flatbed is its skirted design, which adds an extra layer of protection to your cargo while still providing ample hauling space.<br/><br/>
Compared to other series offered by Bedrock, the Granite Series Skirted Flatbed provides an efficient solution for industries that require frequent transportation of heavy equipment or oversized cargo. <br/><br/>
The standard equipment of the Granite Series Skirted Flatbed includes a gooseneck hitch, a 2” receiver  bumper hitch, integrated toolboxes (2 or 4 depending on model), underbody toolboxes, adjustable headache rack, and stake pockets. Add-ons available include a spare tire, rear corners, air ride truck suspension, and a variety of trailer plugs.<br/><br/>
The Granite Series Skirted Flatbed is available in various sizes, ranging from 8 to 12 feet in length and 7 to 8 feet in width. It has a maximum towing capacity of 30,000 pounds and is finished with a durable powder coat paint.<br/><br/>
Overall, the Granite Series Skirted Flatbed combines both functionality and durability to provide a reliable solution for hauling heavy cargo.
`,
  },
  {
    header: "Marble Series Utility Flatbeds",
    content: `<div class='accordion-img'><img src="/assets/marble-flat.png"  alt="Pickup Truck with Bedrock Marble Series Utility Flatbed"/></div>Introducing the Marble Series Utility Flatbeds, designed by Bedrock for maximum functionality and durability. Constructed from 12-gauge steel, these flatbeds offer unparalleled strength and resilience, ideal for heavy applications in the construction, agricultural, and industrial industries.<br/><br>
Standard features of the Marble Series Utility Flatbeds include stake pockets for secure tie-downs and an underbody toolbox for convenient storage. Options available include a gooseneck hitch for reliable towing and an integrated headache rack for added protection.<br/><br/>
The Marble Series Utility Flatbeds are available in various sizes to accommodate different truck models and are customized to fit a range of applications. These flatbeds also come with a durable powder coat finish to withstand harsh weather conditions and resist corrosion, providing customers with years of dependable service.<br/><br/>
In conclusion, the Marble Series Utility Flatbeds are a perfect choice for those who require a functional, durable, and long-lasting flatbed for their trucks. With their array of features and options, these flatbeds ensure maximum efficiency and reliability for all your hauling needs, all while taking care of your cargo.<br/><br/>
This bed offers the most storage with 2 topsider boxes as well as a large storage box on each front corner and 2 smaller boxes on each rear corner.
`,
  },
];

export const FlatbedPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Flatbeds | Upfitter and Official Distributor of Bedrock Flatbeds and
          work bodies | Knollwood Tire & Wheel
        </title>
        <meta
          name="description"
          content="Discover the best in functional truck mods with our range of Bedrock flatbeds at Knollwood Tire & Wheel, Georgia's leading truck accessory provider. Upgrade your truck with a Bedrock flatbed today and experience the difference in performance and capability. Visit our Georgia-based shops or contact our expert team for advice tailored to your needs."
        />
        <meta
          name="keywords"
          content="Utility truck flatbeds, flatbed, steel flatbeds, Bedrock flatbeds, bedrock, knollwood Tire & Wheel, utility truck accessories."
        />
      </Helmet>
      <GlobalComponents.Container>
        <GlobalComponents.GlobalH1>
          What Type of Flatbed
          <br />
          <GlobalComponents.GlobalH1Accent>
            Is Best For You?
          </GlobalComponents.GlobalH1Accent>
        </GlobalComponents.GlobalH1>
        <LargeImg>
          <img src="/assets/bedrock-full.png" alt="Pickup Truck with flatbed" />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          When it comes to flatbed options, Bedrock offers a variety of choices
          to meet the needs of different customers and trade industries.. For
          those who need a durable and heavy-duty flatbed, the steel flatbed
          option is the best choice. With a weight capacity of up to 30,000
          pounds, it can handle even the heaviest loads.
          <br />
          <br />
          For customers who demand both quality and versatility, Bedrock's
          Diamond series flatbed would be the best option. It is made from
          steel, able to handle any cargo type. It comes with an assortment of
          standard features such as stake pockets, and more. For steel truck
          beds, the Diamond series cannot be beat.
          <br />
          <br />
          The choice of the flatbed ultimately depends on the industry and needs
          of your truck , as well as your preferences and the compatibility of
          the flatbed with your specific vehicle. Bedrock flatbeds are designed
          for longevity and durability, ensuring that your investment lasts for
          years to come.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>Steel Flatbeds</GlobalComponents.GlobalH2>
        <GlobalComponents.MainParagraph>
          Steel flatbeds have been a staple in the trucking industry for
          decades. They are known for their durability and longevity, making
          them a popular choice for those who need a reliable flatbed for their
          business or personal use. Steel flatbeds come in a range of sizes and
          feature a variety of options to cater to different customer needs.
          From trailer plugs and gussets to integrated toolboxes and underbody
          toolboxes, steel flatbeds offer a lot of customization options for
          customers. Let's take a closer look at the features and benefits of
          steel flatbeds within the Bedrock lineup.{" "}
        </GlobalComponents.MainParagraph>
        <BrandWrapper>
          <BrandContainer>
            <BrandLogoContainer>
              <BrandLogoImg
                src="/assets/bedrock-logo.png"
                alt="Logo for Bedrock Flatbeds"
              />
            </BrandLogoContainer>
            <BrandNameContainer>
              <BrandNameText>Bedrock Flatbeds</BrandNameText>
            </BrandNameContainer>
          </BrandContainer>
          <BrandParagraph>
            Bedrock Flatbeds offers a range of flatbeds for pickup trucks and
            other types of vehicles, designed to meet the needs of different
            industries and users. Their product line includes multiple series
            with different features and options, such as the Heavy Duty,
            Contractor, and hobbyist models. Customers can also choose from
            various finishing options, including powder coat paint and different
            colors (Bedrock flatbeds are powder coated with a corrosion & UV
            resistant gloss black finish)
            <br />
            <br />
            One of the standout features of Bedrock Flatbeds are their
            adjustable design, allowing users to customize the bed to their
            specific needs. For example, the gooseneck hitch can be adjusted as
            needed through its B&W turnoverball system with 30,000 lb. capacity.
            The headache rack can be adjusted for cab clearance and optional
            lighting. Additionally, on certain Bedrock models, the stake pockets
            and tie-downs can be moved to accommodate different types of cargo.
            You want a flatbed truck that works for you, and Bedrock is the
            brand we recommend here as an expert in Georgia utility truck
            builds.
            <br />
            <br />
            <i>
              Did you know that the headache rack of Bedrock flatbeds have 3
              adjustments to allow for specialized applications while still
              maintaining sleek aesthetics? They are the only company to have a
              notch in the top of their headache racks to allow for bed view
              cameras and 3rd brake light functionality!
            </i>
            <br />
            <br />
            Bedrock Flatbeds are built to last and come with a quality
            guarantee. The company uses high-quality materials and advanced
            construction techniques, such as CNC plasma cutting and robotic
            welding, to ensure superior strength and durability. The floors are
            made of rugged tread plate steel, and the bedframe is reinforced
            with gussets and crossmembers. Bedrock Flatbeds also offers a
            limited lifetime warranty on certain structural components, ensuring
            that customers can rely on their flatbed for years to come. Bedrock
            Flatbeds are a great choice for anyone looking for a reliable,
            adjustable, and high-quality flatbed for their truck or vehicle.
          </BrandParagraph>
          <ProductAccordion items={sections} />
        </BrandWrapper>
        <GlobalComponents.GlobalH3>
          About Bedrock Flatbeds
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Bedrock Flatbeds offer a range of unique features across their
          different series of flatbeds. The Diamond Series boasts an all-steel
          construction with a tread plate floor (and) the flexibility to add
          underbody boxes as needed.. The Limestone Series offers a more
          affordable option with a steel frame and aluminum skin, and the Slate
          Series is a lightweight option with an aluminum frame and skin.
          <br />
          <br />
          Bedrock is committed to expert craftsmanship; building all of their
          flatbeds with durability and longevity in mind. They use high-quality
          materials and construction techniques, ensuring each flatbed can
          withstand heavy wear and tear.
          <LargeImg>
            <img
              src="/assets/bedrock-headache-leds.png"
              alt="Bedrock Flatbed with LEDs"
            />
          </LargeImg>
          Bedrock also offers a top notch finishing process, including a powder
          coat paint finish and diamond plate accents. They stand behind their
          product with a quality guarantee, ensuring that each flatbed is free
          of defects and built to the highest standards. Overall, Bedrock
          Flatbeds offer a range of unique features, expert construction, and
          high-quality finishing options, making them a top choice for those in
          need of a durable and customizable flatbed.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          How Bedrock Builds Their Flatbeds
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Bedrock flatbeds are thoughtfully designed and manufactured with
          premium quality materials to deliver industry-best performance.
          Production at Bedrock involves a rigorous design process that tailors
          each flatbed to the specific needs of applicable businesses and
          industries. Research and development considers load requirements,
          cargo handling, weight distribution and more toensure that the final
          product is optimally designed for its purpose.
          <br />
          <br />
          Bedrock uses high-strength steel to construct their flatbeds, offering
          unmatched durability and longevity. The manufacturing process of
          Bedrock flatbeds employs cutting-edge technologies such as laser
          cutting and CNC forming to precisely craft each flatbed. The use of
          these technologies guarantees consistent quality in the final product,
          adding to the long-lasting nature of Bedrock flatbeds.
          <LargeImg>
            <img
              src="/assets/bedrock-rear-leds.png"
              alt="Bedrock Flatbed rear end with LEDs"
            />
          </LargeImg>
          Additionally, Bedrock flatbeds come with unique features such as
          adjustable heights, customized mounting options, integrated toolboxes
          and stake pockets. These features enhance the flexibility, durability,
          and efficiency of Bedrock flatbeds, making them ideal for various
          applications in agriculture, construction, electrical, plumbing, and
          over-the-road hauling. Bedrock places a high priority on customer
          satisfaction, and every flatbed they produce undergoes rigorous
          quality testing to ensure that it meets customer requirements.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>Quality Guarantee</GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Bedrock Flatbeds offers a comprehensive warranty of 3 years or 36,000
          miles. This warranty assures customers that their flatbeds are of the
          highest quality and durability. The warranty covers defects in
          materials and workmanship and guarantees that Bedrock flatbeds will
          function as intended under normal use and service.
          <br />
          <br />
          Additionally, the warranty includes coverage for items such as the
          tail board, LED lights, and wiring harness. Bedrock stands behind
          their products and is committed to providing customers with flatbeds
          that will last them for years to come.
          <br />
          <br />
          To ensure that customers receive the highest quality products and
          service, Bedrock has partnered with exclusive distributors such as
          Knollwood Tire & Wheel, an expert in Bedrock flatbeds. As one of the
          exclusive distributors in the Southeast, customers can rest assured
          that their Bedrock flatbeds are installed and serviced by trained
          professionals who understand the product and its capabilities.
          <br />
          <br />
          In conclusion, Bedrock Flatbeds' comprehensive warranty and
          partnership with distributors like Knollwood Tire & Wheel guarantee
          the quality and durability of their products. Customers can be
          confident in the longevity of their Bedrock flatbeds and the service
          they receive throughout the lifetime of the product.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Bedrock Finishing Options
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Bedrock Flatbeds not only excel in strength, durability, and
          longevity, but they also allow you to personalize your flatbed with a
          variety of finishing options. These finishing options are designed to
          provide you with a unique and functional bed that meets your specific
          needs.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Towing Capacity & Design
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Bedrock Flatbeds are designed to offer immense towing capacity and a
          range of design features that make them ideal for hauling heavy loads.
          The flatbeds are available in a variety of series, each with its own
          load rating and construction features.
        </GlobalComponents.MainParagraph>
        <ProductComparisonContainer>
          <ProductComparisonTable>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Diamond Series</th>
                <th>Granite Series</th>
                <th>Marble Series</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Available Length (ft)</td>

                <td>7' • 8'6" • 9'4" • 11'4"</td>
                <td>7' • 8'6" • 9'4" • 11'4"</td>
                <td>7' • 8'6" • 9'4" • 11'4"</td>
              </tr>
              <tr>
                <td>Material</td>

                <td>
                  Full steel frame with 4'' & 3'' structural C-channel
                  construction & 1/8'' steel
                </td>
                <td>
                  Full steel frame with 4'' & 3'' structural C-channel
                  construction & 1/8'' steel
                </td>
                <td>
                  Full steel frame with 4'' & 3'' structural C-channel
                  construction & 1/8'' steel
                </td>
              </tr>
              <tr>
                <td>Skirted or Non-Skirted</td>

                <td>Non-skirted bed</td>
                <td>Skirted bed with integrated toolboxes</td>
                <td>Skirted bed with integrated toolboxes and workspace</td>
              </tr>
              <tr>
                <td>Finish Options</td>

                <td>Diamond plate deck, rear, and sides</td>
                <td>Diamond plate deck with smooth steel rear and sides</td>
                <td>
                  Diamond plate deck with smooth steel rear and sides along with
                  tapered corners for better turn radius while towing
                </td>
              </tr>
              <tr>
                <td>Pocket Design</td>

                <td>3/8” x 2” rub rail and stake pockets</td>
                <td>3/8” x 2” rub rail and stake pockets</td>
                <td>
                  (6) 360-degree flush mount zinc plated tie downs, capacity
                  2000 lb. each
                </td>
              </tr>
              <tr>
                <td>Lighting System</td>

                <td>
                  Standard LED Lighting - DOT Approved
                  <br />
                  Standard locations W/ Back-up Lights in headache rack
                </td>
                <td>
                  Standard LED Lighting - DOT Approved
                  <br />
                  Standard locations W/ Back-up Lights in headache rack
                </td>
                <td>
                  (5) High intensity LED worklights for a combined 5,000 lumens
                  - 2 on the rear, 2 in the tailgate and 1 cargo work light
                </td>
              </tr>
              <tr>
                <td>Wiring System</td>

                <td>7-way & 4-way trailer wiring plug</td>
                <td>7-way & 4-way trailer wiring plug</td>
                <td>7-way & 4-way trailer wiring plug</td>
              </tr>
              <tr>
                <td>Receiver Hitch System</td>

                <td>Integrated 2'' receiver hitch rated at 18,000 lbs</td>
                <td>Integrated 2'' receiver hitch rated at 18,000 lbs</td>
                <td>Integrated 2'' receiver hitch rated at 18,000 lbs</td>
              </tr>
              <tr>
                <td>Integrated Gooseneck Hitch?</td>

                <td>Yes - B&W 2 5/16'' welded ball rated at 30,000 lbs</td>
                <td>Yes - B&W 2 5/16'' welded ball rated at 30,000 lbs</td>
                <td>Yes - B&W 2 5/16'' welded ball rated at 30,000 lbs</td>
              </tr>
              <tr>
                <td>Available for Dual Rear Wheels?</td>

                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Available in Quad System?</td>

                <td>Yes</td>
                <td>Yes</td>
                <td>No</td>
              </tr>
              <tr>
                <td>
                  <strong>Why Choose This Bedrock Bed?</strong>
                </td>

                <td>
                  <span>
                    The Diamond series is a <strong>non-skirted</strong> bed
                    designed for limitless bed options and accessories. While
                    it's a non-skirted bed, the Diamond series retains a diamond
                    plate deck, rear, and sides along with 3/8" x 2" rub rail
                    and stake pockets
                  </span>
                </td>
                <td>
                  <span>
                    The Granite series features a <strong>skirted</strong>{" "}
                    design with built-in weatherproof toolboxes. The Granite
                    series features 3/8" x 2" rub rail and stake pockets, plenty
                    of landscape for modular design based on your needs, and a
                    durable diamond plate trim for all cargo needs.
                  </span>
                </td>
                <td>
                  <span>
                    Introduced as Bedrock's cutting-edge black out truck bed,
                    the Marble series is the ultimate solution for all your
                    storage needs. With state-of-the-art weatherproof toolboxes
                    and four flush mount worklights, this bed sets a new
                    standard in versatility.
                    <br />
                    <br />
                    The Marble Series also has improved lighting systems. Each
                    bed includes five high intensity LED worklights, boasting a
                    combined 5,000 lumens. These lights are strategically
                    positioned, with two on the rear, two in the tailgate, and
                    one cargo work light. Notably, the innovative tailgate
                    worklights offer an impressive 180-degree vertical worklight
                    coverage, ensuring maximum visibility.
                    <br />
                    <br />
                    Safety and convenience are improved as well. A 2 position
                    worklight switch located within the toolbox for enhanced
                    security as well as the 8'' Tail Gate comes equipped with a
                    paddle latch and slam closure.
                    <br />
                    <br />
                    The single rear wheel and all cab and chassis options
                    feature a spacious 50'' wide deck, while the dual rear wheel
                    take-off beds offer a generous 53'' wide deck. All cargo is
                    secured with six 360-degree flush mount zinc plated tie
                    downs. Each tie down boasts an impressive 2,000 lb.
                    capacity.
                  </span>
                </td>
              </tr>
            </tbody>
          </ProductComparisonTable>
        </ProductComparisonContainer>
        <GlobalComponents.MainParagraph>
          The standard series flatbeds offer a load rating of up to 26,000
          pounds with the 2” standard receiver, while the heavy-duty series
          flatbeds have a load rating of up to 36,000 pounds with a 2.5”
          receiver . The towing capacity of these flatbeds is enhanced by their
          design features, which include integrated hitches and high-quality
          construction materials.
          <br />
          <br />
          Bedrock Flatbeds are constructed using steel with a tread plate floor
          and trailer gussets, which add additional strength and support where
          needed. The stake pockets provide a secure anchor point for cargo
          tie-downs, while the headache rack provides additional protection for
          the cab and assists in cargo tie-down.
          <br />
          <br />
          Furthermore, the flatbeds come with a rated gooseneck hitch and a
          strong receiver type bumper hitch, both of which add to the towing
          capacity of these flatbeds. With an integrated toolbox and spare tire,
          these flatbeds offer both functionality and convenience.
          <br />
          <br />
          Bedrock Flatbeds are a high-quality option for those seeking to haul
          heavy loads. Their towing capacity is supported by their design
          features, such as integrated hitches and durable construction
          materials. When it comes to hauling heavy loads, Bedrock Flatbeds are
          the industry-best flatbed truck bodies to choose from.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>Adjustability</GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          When it comes to choosing the right flatbed for your truck, one of the
          key factors to consider is adjustability. A flatbed that offers
          various adjustment options can make a huge difference in the way you
          use your truck and the efficiency of your operations.
          <br />
          <br />
          Bedrock Flatbeds offer a high degree of adjustability, allowing you to
          optimize your truck for all sorts of tasks. Their flatbeds are
          designed with a variety of features that can be adjusted to meet your
          specific needs, including:
          <br />
          <br />
          Height: Bedrock Flatbeds offer adjustable height options, allowing you
          to raise or lower the flatbed as needed. This can be particularly
          useful if you frequently carry large or tall equipment and need
          additional clearance.
          <br />
          <br />
          Length: Some Bedrock Flatbeds also offer adjustable length options.
          This can be useful if you need extra space for larger loads or if you
          want to customize your truck for a specific task.
          <br />
          <br />
          With these adjustment options, Bedrock Flatbeds make it easier than
          ever to optimize your truck for your specific needs. And because they
          are made with high-quality materials and construction techniques, you
          can trust that your Bedrock Flatbed will provide reliable performance
          for years to come.
          <br />
          <br />
          In summary, adjustability is a critical feature to look for when
          choosing a flatbed for your truck. With its versatile range of
          adjustment options, Bedrock Flatbeds offer both functionality and
          convenience, allowing you to customize your truck for all sorts of
          tasks. So if you're looking for a durable, reliable truck bed that can
          meet all of your needs, look no further than Bedrock Flatbeds.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Flatbed Sales & Installation in Georgia
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          If you're looking for a high-quality flatbed for your truck in the
          SouthEast U.S., Knollwood Tire & Wheel offers the best pricing and
          installation on Bedrock Flatbeds. We offer all flatbeds for trucks,
          but prioritize Bedrock for quality and utility use. We quote, install
          and handle the entire process of flatbed installation at our locations
          in Warner Robins and Douglas, Georgia.
          <br />
          <br />
          Bedrock Flatbeds come with a variety of standard features, including
          trailer plugs, integrated toolboxes, stake pockets,and a powder-coat
          paint finish for added durability. The flatbeds also have tread plate
          floors and underbody toolboxes for extra storage space.
          <br />
          <br />
          To purchase and install a Bedrock Flatbed in Georgia, simply contact
          us here or through phone at either location. To reach the Douglas
          store please call <a href="tel:+19122600005">(912) 260-0005</a>. To
          reach the Warner Robins location please call{" "}
          <a href="tel:+14783025054">(478) 302-5054</a>. We will walk you
          through the available options, assist with customizations, provide
          shipping and delivery options, and schedule installation with one of
          our professional bed installers. With Bedrock Flatbeds through
          Knollwood Tire & Wheel, you can be sure that you are getting a
          dependable, high-quality truck bed that will meet your needs for years
          to come.
        </GlobalComponents.MainParagraph>
      </GlobalComponents.Container>
    </>
  );
};
