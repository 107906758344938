import * as React from "react";
import { Helmet } from "react-helmet";

export const GlobalHelmet = () => {
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width" />
      <title>
        Georgia’s Premier Truck Shop: Bedrock Flatbeds, OEM Takeoffs, CamLocker
        Toolboxes & More.
      </title>
      <meta
        name="description"
        content="The premier destination for truck accessories in Georgia. Specializing in Bedrock Flatbeds, OEM takeoffs, Steelcraft bumpers, Camlocker toolboxes, and Centennial tires, we provide a comprehensive range of high-quality products to enhance and secure your truck. With Knollwood Tire & Wheel, experience top-notch service and the best truck supplies that Georgia has to offer. Visit us today for all your truck accessory needs"
      />
      <meta
        name="keywords"
        content="Utility truck wheels and tires, flatbed truck installation, steel flatbeds, Bedrock flatbeds, oem wheels for sale, camlocker toolboxes, centennial tires, flat beds, utility truck accessories."
      />
      <script type="text/javascript" key="function" id="facebook-pixel">
        {`
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','//connect.facebook.net/en_US/fbevents.js');
    fbq('init', '654816199575350');
    fbq('track', 'PageView');`}
      </script>

      <noscript key="image" id="facebook-pixel-image">
        {`
                <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=654816199575350&ev=PageView&noscript=1"/>
                `}
      </noscript>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-na1.hs-scripts.com/39894777.js"
      ></script>
    </Helmet>
  );
};
