import * as React from "react";
import styled from "styled-components";
import { GenericH2 } from "../../../Global/TextHeaders";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 85vw;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 0;
  outline: 0;
  font-size: 2.5rem;
  background: transparent;
  font-weight: 500;
  cursor: pointer;
  padding: 0 1rem;
`;

export const AtcModal: React.FC<{
  showForm: boolean;
  closeForm: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ showForm, closeForm }) => {
  React.useEffect(() => {
    if (showForm) {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        // @ts-ignore
        if (window.hbspt) {
          // @ts-ignore
          window.hbspt.forms.create({
            region: "na1",
            portalId: "39894777",
            formId: "26fdcf15-cf5c-434f-95f9-914d8a8e846c",
            target: "#hubspotForm",
          });
        }
      });
    }
  }, [showForm]);

  return (
    <>
      {showForm && (
        <ModalContainer>
          <ModalContent>
            <GenericH2>Submit Quote</GenericH2>
            <CloseButton onClick={closeForm}>&times;</CloseButton>
            <div id="hubspotForm"></div>
          </ModalContent>
        </ModalContainer>
      )}
    </>
  );
};
