import { Box, Grid } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Helmet } from 'react-helmet';
import * as GlobalComponents from '../../globalStyles';

export const ServicesPage = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    return (
        <Box>
            <Helmet>
                <title>
                    Learn about our truck services & accessories offered in Georgia. Tire installs, flatbeds, bumper install and more!
                </title>
                <meta
                    name="description"
                    content="We offer all services and products needed to build your utility truck or vehicle. With locations in Warner Robins & Douglas, Georgia, we’re just a call away to help with your truck needs."
                />
                <meta
                    name="keywords"
                    content="Utility truck wheels and tires, truck services, truck shop,  flatbed truck installation, steel flatbeds, Bedrock flatbeds, oem wheels, camlocker toolboxes, tires, flat beds, truck accessories, takeoff wheels."
                />
            </Helmet>
            <GlobalComponents.Container>
                <GlobalComponents.GlobalH1>
                    Our Services| Knollwood Tire & Wheel
                </GlobalComponents.GlobalH1>
                <GlobalComponents.MainParagraph>
                    At Knollwood Tire & Wheel, we offer services and products that allow you to build your vehicle for on-road and off-road needs. Take a look at our full range of services below and give us a call at <a href="tel:+19122600005">(912) 260-0005</a>  or email us at <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> for scheduling your appointment.
                    <ul>
                        <li>Flatbed & Truck Bumper Installation</li>
                        <li>OEM & Aftermarket Tire Replacement</li>
                        <ul>
                            <li>Laser Mount & Balance</li>
                            <li>4 Wheel Laser Alignment</li>
                        </ul>
                        <li>Lighting Wiring & Installation (Recreational & Commercial)</li>
                        <li>Custom Builds / Commercial Trucks</li>
                        <li>Aftermarket Suspension & Leveling Kits</li>
                        <li>Truck Accessories Catalog</li>
                        <li>Lowest Price OEM Takeoff Wheel & Tire Package</li>
                        <li>Spray In Bed Liner (Warner Robins Location exclusively)</li>
                        <li>Full Lobby Customer Service Support</li>
                    </ul>
                </GlobalComponents.MainParagraph>
                <GlobalComponents.GlobalH2>
                    Knollwood Tire & Wheel Warner Robins, GA
                </GlobalComponents.GlobalH2>
                <GlobalComponents.MainParagraph>
                    Located right off Watson Blvd (3 minutes off of Hwy 129) is our newly renovated location. Inside of our Warner Robins store you will find brand new flooring, lighting, and services since our purchase of the building in February of 2022. View our in-stock inventory of OEM takeoffs or relax in our newly furnished customer lobby.
                    <br />
                    <br />
                    Knollwood Tire & Wheel Warner Robins Phone: <a href="tel:+14783025054">(478) 302-5054</a>
                </GlobalComponents.MainParagraph>
                <GlobalComponents.GlobalH2>
                    Knollwood Tire & Wheel Douglas, GA
                </GlobalComponents.GlobalH2>
                <GlobalComponents.MainParagraph>
                    Our first store features a 9,000 sq. ft. storefront and features all the newest products and wheel/tire packages offered by Knollwood Tire & Wheel. As seen on <a href="https://douglasnow.com/index.php/community/item/11261-chamber-holds-ribbon-cutting-for-knollwood-tire-wheel-s-second-location" target="_blank">Douglas Now</a>, our douglas location is located minutes away from Tip Top Deli and is heavily rooted in Coffee County. With a unique 1:1 customer experience and a dedication to offering OEM takeoffs at the lowest price, our Douglas store is ready for your call or walk in.
                    <br />
                    <br />
                    Knollwood Tire & Wheel Douglas Phone: <a href="tel:+19122600005">(912) 260-0005</a>
                </GlobalComponents.MainParagraph>
                <GlobalComponents.GlobalH2>
                    Our Customer Guarantee
                </GlobalComponents.GlobalH2>
                <GlobalComponents.MainParagraph>
                    At Knollwood Tire & Wheel, we take immense pride in our commitment to authenticity and respect. Because of that, we offer a Knollwood Road Hazard Protection Program on all tires purchased through any Knollwood Tire & Wheel Location.
                    <br />
                    <ul>
                        <li>During the first 20% of treadwear, we offer free tire replacement.</li>
                        <li>After 20% of tire wear, replacement percent is prorated.</li>
                        <li>We provide free tire repair up to $20 per repair.</li>
                        <li>Nationwide Coverage.</li>
                        <li>Our tire warranty is valid for 3 years or 2/32" of tread, whichever comes first.</li>
                        <li>Truck Accessories Installation.</li>
                        <li>We provide peace of mind and a layer of insurance when purchasing tires.</li>
                    </ul>
                    We're not just here to sell tires and wheels; we're here to make sure you get the right solution for your needs. The customer reviews for our services affirm this commitment. Our customers, most of whom belong to the hardworking class of individuals that build and modify trucks, appreciate our no-nonsense, down-to-earth approach.
                </GlobalComponents.MainParagraph>
                <GlobalComponents.GlobalH2>
                    Products Offered
                </GlobalComponents.GlobalH2>
                <GlobalComponents.MainParagraph>
                    Knollwood Tire & Wheel offer the top brands with the newest catalogs available for your to look at and purchase at our locations.
                    <ul>
                        <li>Fuel Wheels</li>
                        <li>Moto Metal</li>
                        <li>KC1 Forged</li>
                        <li>American Force</li>
                        <li>Method Race Wheels</li>
                        <li>Ultra Wheels</li>
                        <li><a href="/products/oemtakeoff" target="_blank">OEM Takeoff</a></li>
                        <li>& more!</li>
                    </ul>
                    Tire Brands:
                    <ul>
                        <li>Centennial Tires</li>
                        <li>Toyo Tires</li>
                        <li>Nitto Tires</li>
                        <li>Venom Tires</li>
                        <li>& more!</li>
                    </ul>
                    Accessory Brands:
                    <ul>
                        <li>AMP Steps</li>
                        <li><a href="/products/flatbeds" target="_blank">Bedrock Flatbeds</a></li>
                        <li><a href="/products/steelcraft" target="_blank">Steelcraft Bumpers</a></li>
                        <li><a href="/products/oemtakeoff" target="_blank">OEM Takeoff Wheel & Tire Packages</a></li>
                        <li>& more!</li>
                    </ul>
                </GlobalComponents.MainParagraph>
            </GlobalComponents.Container>
        </Box>
    );
};
