import * as React from "react";
import { Helmet } from "react-helmet";
import * as GlobalComponents from "../../../globalStyles";
import { LargeImg } from "../../../common/components/Images";

export const TakeoffPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          OEM Wheel & Takeoffs | Upfitter and Largest Distributor of OEM Wheel &
          Tire Takeoffs | Knollwood Tire & Wheel
        </title>
        <meta
          name="description"
          content="Discover the largest and lowest price on OEM takeoff wheels and tires for your truck or SUV at Knollwood Tire & Wheel, Georgia's leading truck accessory provider. Upgrade your truck with an OEM wheel or package today. Visit our Georgia-based shops or contact our expert team."
        />
        <meta
          name="keywords"
          content="OEM Takeoff, Factory Truck Wheels, OEM Takeoff Wheels,Cheap OEM Wheels, truck tires, takeoff truck parts,  knollwood Tire & Wheel, utility truck accessories."
        />
      </Helmet>
      <GlobalComponents.Container>
        <GlobalComponents.GlobalH1>
          What are
          <br />
          <GlobalComponents.GlobalH1Accent>
            OEM Takeoff Wheels?
          </GlobalComponents.GlobalH1Accent>
        </GlobalComponents.GlobalH1>
        <LargeImg>
          <img
            src="/assets/oem-wheels-pickup-truck.webp"
            alt="Pickup Truck with OEM Wheels"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          OEM takeoff wheels refer to wheels that have been removed from a
          vehicle shortly after it was purchased and replaced with aftermarket
          or custom wheels. These original equipment manufacturer (OEM) wheels
          are typically in excellent condition, with little to no wear or
          damage. OEM takeoff wheels offer truck owners the opportunity to
          upgrade their vehicle's wheels without the expense of buying brand new
          ones.
          <br />
          <br />
          They are often sold at a lower price than new wheels and can be a very
          affordable option for those looking to maintain the original design
          and performance of their vehicle. At Knollwood Tire & Wheel, we
          understand the value of OEM takeoff wheels and offer a wide selection
          of high-quality options to suit various makes and models. Not only
          that, but we package brand new aftermarket tires on these wheels with
          installation at our local Georgia based shops upon purchase.
          <br />
          <br />
          We have all makes and brands of OEM Takeoffs at the lowest price on
          the internet; guaranteed. Give us a call at{" "}
          <a href="tel:+19122600005">(912) 260-0005</a> or email us at{" "}
          <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> to
          inquire about our inventory of OEM takeoff wheels and experience our
          exceptional customer service.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>
          Why Choose OEM Takeoff Wheels?
        </GlobalComponents.GlobalH2>
        <GlobalComponents.MainParagraph>
          OEM Takeoff wheels offer the same excellent quality you can expect
          from an original manufacturer, at a cost that often beats out
          aftermarket wheel brands. Paired with a set of aftermarket tires or
          factory OE tires and you get a look that updates the age of your
          vehicle at a fraction of the cost when compared to fully aftermarket
          wheels.
          <br />
          <br />
          Another main advantage of choosing OEM Takeoff wheels is their high
          quality and reliability. These wheels are factory original, meaning
          they were made to exact specifications by the original equipment
          manufacturer. This ensures a perfect fit and compatibility with your
          vehicle, promoting optimal performance and safety.
          <br />
          <br />
          If you're in the market for new wheels, consider the benefits of
          choosing OEM Takeoff wheels. With their high-quality construction,
          cost-effectiveness, and wide range of options, these wheels are a
          practical choice for any vehicle owner. Contact Knollwood Tire & Wheel
          at <a href="tel:+19122600005">(912) 260-0005</a> or email us at{" "}
          <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> to
          inquire about our inventory of OEM Takeoff wheels and find the perfect
          fit for your vehicle.{" "}
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>
          Types of OEM Takeoff Wheels
        </GlobalComponents.GlobalH2>
        <LargeImg>
          <img
            src="/assets/rst-cover.png"
            alt="Chevrolet OEM Takeoff Wheel with Goodyear Wrangler Tire"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          Takeoff wheels consist of OEM wheels from manufacturers like Toyota,
          Ford, Chevy, Jeep and more. From rare trims like the Raptor wheels or
          upsized snowflakes, Knollwood Tire & Wheel truly has the largest
          takeoff inventory in the United States. Below are some common types of
          available takeoff wheels.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>Steel Wheels</GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Steel Wheels: Durability and Cost Efficiency in OEM Takeoff Options
          <br />
          <br />
          When it comes to OEM takeoff options, steel wheels are an excellent
          choice for their durability and cost efficiency. These wheels are
          designed to withstand tough road conditions, making them a reliable
          and long-lasting option for your vehicle.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>Alloy Wheels</GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          When it comes to OEM takeoff options, alloy wheels are a popular
          choice that combine style, performance, and durability. These wheels
          are made from a combination of aluminum and other metals, resulting in
          a lightweight yet strong construction.
          <br />
          <br />
          One of the key features of alloy wheels is their aesthetic appeal.
          With various finishes available, including polished, chrome, and
          matte, alloy wheels can give your vehicle a sleek and stylish look.
          Additionally, they often come in unique designs that can enhance the
          overall appearance of your truck.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>
          Best Priced Takeoff Wheel Sizes
        </GlobalComponents.GlobalH2>
        <GlobalComponents.GlobalH3>
          Ford Takeoff Wheels
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Some popular Ford models include the Escape, F-150, Fusion and
          Mustang. Below are just some of the Ford Takeoff Wheels available from
          Knollwood Tire & Wheel:
          <ul>
            <li>
              17”x7” Ford F-150 XLT Aluminum Alloy Rim 5 Lugs 114.3mm Bolt
              Pattern (6R3Z1007B)
            </li>
            <li>
              17”x8" Ford Edge SE/SEL Aluminum Machined Alloy Rim 5 Lugs 114.3mm
              Bolt Pattern (BL1Z1007C)
            </li>
            <li>
              18”x8" Ford Edge Titanium/Sport Aluminum Alloy Rim 5 Lugs 114.3mm
              Bolt Pattern (BL1C1007H)
            </li>
            <li>
              19”x8" Ford Mustang GT Aluminum Machined Alloy Rim 5 Lugs 114.3mm
              Bolt Pattern (AR3C1007D)
            </li>
            <li>
              20”x9" Ford Super Duty Platinum/King Ranch Aluminum Alloy Rim 8
              Lugs 170mm Bolt Pattern (DD631007AJK)
            </li>
          </ul>
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Chevy Takeoff Wheels
        </GlobalComponents.GlobalH3>
        <LargeImg>
          <img
            src="/assets/oem-takeoff-chevy-focus.png"
            alt="Chevrolet OEM Takeoff Wheel"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          There are several popular Chevy models such as the Silverado,
          Suburban, Tahoe and Equinox. Below are just some of the Chevy Takeoff
          Wheels available from Knollwood Tire & Wheel:
          <ul>
            <li>
              17”x7.5” Chevy Silverado 1500 LTZ Aluminum Machined Alloy Rim 6
              Lugs 139.7mm Bolt Pattern (22752775)
            </li>
            <li>
              18”x8.5" Chevy Silverado 1500 LTZ Aluminum Polished Alloy Rim 6
              Lugs 139.7mm Bolt Pattern (22752777)
            </li>
            <li>
              20”x8.5" Chevy Suburban LS/LT Aluminum Machined Alloy Rim 6 Lugs
              139.7mm Bolt Pattern (22754179)
            </li>
            <li>
              20”x9" Chevy Tahoe LS/LT/High Country Aluminum Polished Alloy Rim
              6 Lugs 139.7mm Bolt Pattern (22754681)
            </li>
            <li>
              22”x9" Chevy Equinox LT Aluminum Polished Alloy Rim 5 Lugs 127mm
              Bolt Pattern (22754467)
            </li>
          </ul>
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Jeep Takeoff Wheels
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          The lineup of available Jeep Takeoff Wheels includes popular models
          such as the Grand Cherokee, Wrangler, and Renegade. Below are just
          some of the Jeep Takeoff Wheels available from Knollwood Tire & Wheel:
          <ul>
            <li>
              17”x7.5” Jeep Grand Cherokee Laredo Aluminum Alloy Rim 5 Lugs
              114.3mm Bolt Pattern (1UY91TRMAA)
            </li>
            <li>
              18”x7.5" Jeep Grand Cherokee Altitude/Limited/Overland Aluminum
              Alloy Rim 5 Lugs 114.3mm Bolt Pattern (1KC91SDMAA)
            </li>
            <li>
              18”x7.5" Jeep Wrangler Rubicon/Sahara Aluminum Alloy Rim 5 Lugs
              114.3mm Bolt Pattern (1KX91TRMAA)
            </li>
            <li>
              18"x7.5” Jeep Renegade Altitude/Limited Aluminum Alloy Rim 5 Lugs
              114.3mm Bolt Pattern (1KE91SDMAA)
            </li>
            <li>
              20”x9" Jeep Wrangler Rubicon/Sahara Aluminum Alloy Rim 5 Lugs
              114.3mm Bolt Pattern (1NB91TRMAA)
            </li>
          </ul>
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          OEM Takeoff Wheel & Tire Packages
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          At Knollwood Tire & Wheel, we are proud to offer our customers the
          convenience of purchasing a Takeoff Wheel and Aftermarket Tire
          package. We provide popular aftermarket tires at lower prices than any
          other aftermarket wheel and tire supplier. This allows our customers
          to get an aftermarket wheel and tire package that has OEM quality,
          affordability, and can be installed for free at our locations in
          Georgia.
          <br />
          Our takeoff wheel and tire packages include brands such as Venom
          Power, Centennial, Toyo Tires, Nitto, and more.
          <br />
          Shop our selection{" "}
          <a href="https://oemtakeoff.com/" target="_blank" rel="noreferrer">
            here
          </a>
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Best Selection Available
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          At Knollwood Tire & Wheel, we pride ourselves on offering a wide
          selection of OEM takeoff wheels & tires to suit every vehicle type and
          style preference. Whether you're looking for steel wheels, alloy
          wheels, or custom wheels, we have the best priced wheel & tire
          packages for your vehicle. To find the perfect OEM takeoff wheels for
          your vehicle, give us a call at{" "}
          <a href="tel:+19122600005">(912) 260-0005</a>. Our team of dedicated
          experts is ready to assist you in finding the best wheels for your
          needs. To purchase and install an OEM takeoff wheel & tire package in
          Georgia, simply contact us here or through phone at either location.
          To reach the Douglas store please call{" "}
          <a href="tel:+19122600005">(912) 260-0005</a>. To reach the Warner
          Robins location please call{" "}
          <a href="tel:+14783025054">(478) 302-5054</a>. We will walk you
          through the available options, assist with customizations, provide
          shipping and delivery options, and schedule installation with one of
          our professional installers.
        </GlobalComponents.MainParagraph>
      </GlobalComponents.Container>
    </>
  );
};
