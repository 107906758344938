import styled from 'styled-components';

export const LargeImg = styled.div`
    margin: 24px auto;

    & img {
        display: block;
        width: auto;
        height: 100%;
        max-height: 600px;
        margin: 0 auto;
        
        @media (max-width: 768px) {
            width: 100%;
            height: auto;
        }
    }
`;
