import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

export default function MasonImageList() {
    return (
        <Box>
            <ImageList variant="masonry" cols={3} gap={8}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                            src={`${item.img}?w=248&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </Box>
    );
}

const itemData = [
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_01.jpeg`,
        title: 'Truck 01',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-03.png`,
        title: 'Tires',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_02.jpeg`,
        title: 'Truck 02',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_03.jpeg`,
        title: 'Truck 03',
    },

    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_04.jpeg`,
        title: 'Truck 04',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_05.jpeg`,
        title: 'Truck 05',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_06.jpeg`,
        title: 'Truck 06',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_07.jpeg`,
        title: 'Truck 07',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/bruce_home_08.jpeg`,
        title: 'Truck 08',
    },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-01.jpeg`,
    //   title: 'Truck 01a',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-02.jpeg`,
    //   title: 'Books',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-03.png`,
    //   title: 'Sink',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-04.webp`,
    //   title: 'Kitchen',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-05.jpeg`,
    //   title: 'Blinds',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-01.jpeg`,
    //   title: 'Bed',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-02.jpeg`,
    //   title: 'Books',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-03.png`,
    //   title: 'Sink',
    // },
    // {
    //   img: `${process.env.REACT_APP_STATIC_PREFIX}/truck-01.jpeg`,
    //   title: 'Bed',
    // },
];
