import {Box, Grid} from '@mui/material';
import {HeroText} from './components/HeroText/HeroText';
import {alpha, useTheme} from '@mui/material/styles';
import MasonImageList from './components/MasonImageList/MasonImageList';
import Services from './components/Services';
// import Promotions from "./components/Promotions/Promotions";
import * as React from 'react';
import {useNavigate} from 'react-router-dom';

export const HomePage = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleOnClick = () => {
        // console.log(`you clicked...`)
        navigate(`/douglas-retail-store`);
    };
    // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    //   defaultMatches: true,
    // });
    return (
        <>
            <Grid
                container
                spacing={0}
                sx={{
                    backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0)}, ${alpha(
                        theme.palette.grey['300'],
                        1,
                    )} 100%)`,
                    backgroundRepeat: 'repeat-x',
                    position: 'relative',
                }}
                paddingLeft={3}
                paddingRight={3}>
                <Grid item xs={1} />
                <Grid
                    item
                    xs={8}
                    sx={{marginLeft: 'auto', marginRight: 'auto'}}
                    justifyContent={'center'}
                    justifyItems={'center'}>
                    {/*<Promotions />*/}
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={12} sm={6}>
                    <HeroText />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MasonImageList />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} paddingTop={5} paddingLeft={3} paddingRight={3}>
                    <Services />
                </Grid>
            </Grid>
        </>
    );
};
