import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    ImageList,
    ImageListItem,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import {useTheme} from '@mui/material/styles';
import {HeroTextDouglasRetail} from './components/HeroTextDouglasRetail';

export const DouglasRetailStorePage = (): JSX.Element => {
    const theme = useTheme();
    return (
        <>
            <Grid container spacing={3} paddingLeft={3} paddingRight={3}>
                <Grid item xs={12}>
                    <HeroTextDouglasRetail />
                </Grid>
                <Grid item sm={12}>
                    <ImageList variant="masonry" cols={3} gap={8}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                                <img src={`${item.img}`} srcSet={`${item.img}`} alt={item.title} loading="lazy" />
                                {/*<ImageListItemBar position="below" title={item.author} />*/}
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
                <Grid container spacing={4}>
                    {mock.map((item, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box
                                component={Card}
                                height={1}
                                display={'flex'}
                                flexDirection={'column'}
                                variant={'outlined'}>
                                <CardContent
                                    sx={{
                                        padding: 4,
                                    }}>
                                    <Box marginBottom={2}>
                                        <Typography variant={'h4'} fontWeight={600} gutterBottom>
                                            {item.title}
                                        </Typography>
                                        <Typography color={'text.secondary'}>{item.subtitle}</Typography>
                                    </Box>
                                    <Box display={'flex'} alignItems={'baseline'} marginBottom={2}>
                                        {/*<Typography variant={'h3'} fontWeight={700}>*/}
                                        {/*  50*/}
                                        {/*</Typography>*/}
                                        {/*<Typography*/}
                                        {/*  variant={'subtitle1'}*/}
                                        {/*  color={'text.secondary'}*/}
                                        {/*  fontWeight={700}*/}
                                        {/*>*/}
                                        {/*  year*/}
                                        {/*</Typography>*/}
                                    </Box>
                                    <Grid container spacing={1}>
                                        {item.features.map((feature, j) => (
                                            <Grid item xs={12} key={j}>
                                                <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                                                    <Box
                                                        component={ListItemAvatar}
                                                        minWidth={'auto !important'}
                                                        marginRight={2}>
                                                        <Box
                                                            component={Avatar}
                                                            bgcolor={theme.palette.primary.main}
                                                            width={20}
                                                            height={20}>
                                                            <svg
                                                                width={12}
                                                                height={12}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>
                                                        </Box>
                                                    </Box>
                                                    <ListItemText primary={feature} />
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardContent>
                                {/*<Box flexGrow={1} />*/}
                                {/*<CardActions sx={{ justifyContent: 'flex-end', padding: 4 }}>*/}
                                {/*  <Button size={'large'} variant={'contained'}>*/}
                                {/*    Learn more*/}
                                {/*  </Button>*/}
                                {/*</CardActions>*/}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

const itemData = [
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/tire-stock-02-web.jpg`,
        title: 'Wheels',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/tire-stock-01-web.jpg`,
        title: 'Tires and Rims',
    },

    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/tire-stock-03-web.jpg`,
        title: 'Changing Tire',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/tire-stock-04-web.jpg`,
        title: 'Tread',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/tire-stock-05-web.jpg`,
        title: 'Balance Tire',
    },
    {
        img: `${process.env.REACT_APP_STATIC_PREFIX}/tire-stock-06-web.jpg`,
        title: 'Mud Tire',
    },
];

const mock = [
    {
        title: 'Service',
        subtitle: 'Nothing is more important that you',
        price: {monthly: '$22', annual: '$190'},
        features: ['Fast', 'Friendly', 'Done Right'],
        isHighlighted: false,
    },
    {
        title: 'Professional',
        subtitle: 'We got this...',
        price: {monthly: '$44', annual: '$390'},
        features: [
            'Certified Technicians',
            'Money Back Guarantee',
            'New Equipment',
            // 'API access',
        ],
        isHighlighted: true,
    },
    {
        title: 'What we offer',
        subtitle: 'Your one stop tire shop',
        price: {monthly: '$44', annual: '$390'},
        features: ['Tires', 'Wheels', 'Bumpers', 'Brushguards'],
        isHighlighted: true,
    },
    // {
    //   title: 'Enterprise',
    //   subtitle: 'Ideal for corporate companyes',
    //   price: { monthly: '$77', annual: '$690' },
    //   features: [
    //     'All features',
    //     'Email support',
    //     'Google Ads',
    //     'SSO via Google',
    //     'API access',
    //     'Facebook Ads',
    //   ],
    //   isHighlighted: false,
    // },
];
