import * as React from 'react';
import {BannerContainer, BannerWrapper} from './styles';

interface I_bannerProps {
    text: string;
    link?: string;
}

export const UABanner: React.FC<I_bannerProps> = (props) => {
    return (
        <BannerContainer href={props.link}>
            <BannerWrapper>{props.text}</BannerWrapper>
        </BannerContainer>
    );
};
