import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {Divider} from '@mui/material';

const Footer = (): JSX.Element => {
    const startYear = '2022';
    const [endYear, setEndYear] = useState('2022');
    const [yearRange, setYearRange] = useState(`${startYear} - ${endYear}`);
    const theme = useTheme();
    const {mode} = theme.palette;

    // get endYear
    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear().toString();
        setEndYear(currentYear);
        if (startYear === currentYear) {
            setYearRange(startYear);
        }
    }, []);

    return (
        <Grid container spacing={3} paddingTop={8} paddingLeft={3} paddingRight={3}>
            <Grid item xs={12}>
                <Divider style={{boxShadow: '0px 2px 4px -1px #000000'}} />
            </Grid>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={1}
                    flexDirection={{xs: 'column', sm: 'row'}}>
                    <Box display={'flex'} component="a" href="/" title="theFront" width={80}>
                        <Box
                            component={'img'}
                            src={
                                mode === 'light'
                                    ? `${process.env.REACT_APP_STATIC_PREFIX}/ktw_inverted_logo.png`
                                    : `${process.env.REACT_APP_STATIC_PREFIX}/ktw_inverted_logo.png`
                            }
                            height={1}
                            width={1}
                        />
                    </Box>
                    <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
                        <Box marginTop={1} marginRight={2}>
                            <Link underline="none" component="a" href="/" color="text.primary" variant={'subtitle2'}>
                                Home
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/locations"
                                color="text.primary"
                                variant={'subtitle2'}>
                                Locations
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/about-us"
                                color="text.primary"
                                variant={'subtitle2'}>
                                About Us
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/contact-us"
                                color="text.primary"
                                variant={'subtitle2'}>
                                Contact Us
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/products"
                                color="text.primary"
                                variant={'subtitle2'}>
                                Products
                            </Link>
                        </Box>
                        <Box marginTop={1} marginRight={2}>
                            <Link
                                underline="none"
                                component="a"
                                href="/services"
                                color="text.primary"
                                variant={'subtitle2'}>
                                Services
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography align={'center'} variant={'subtitle2'} color="text.secondary" gutterBottom>
                    &copy; {yearRange} KTW. All rights reserved.
                </Typography>
                <Typography align={'center'} variant={'caption'} color="text.secondary" component={'p'}>
                    When you visit or interact with our sites, services or tools, we or our authorised service providers
                    may use cookies for storing information to help provide you with a better, faster and safer
                    experience and for marketing purposes.
                </Typography>
            </Grid>
            {process.env.REACT_APP_ENV === 'dev' && (
                <>
                    <Grid item xs={12}>
                        <Box display={{xs: 'flex', sm: 'none'}}>XS</Box>
                        <Box display={{xs: 'none', sm: 'flex', md: 'none'}}>SM</Box>
                        <Box display={{xs: 'none', sm: 'none', md: 'flex', lg: 'none', xl: 'none'}}>MD</Box>
                        <Box display={{xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'none'}}>LG</Box>
                        <Box
                            display={{
                                xs: 'none',
                                sm: 'none',
                                md: 'none',
                                lg: 'none',
                                xl: 'flex',
                            }}>
                            XL
                            <br />
                            {process.env.REACT_APP_GANALYTICS}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                        Google Analytics: {process.env.REACT_APP_GANALYTICS}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default Footer;
