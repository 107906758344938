import { Grid } from '@mui/material';

const sitemapContent = './public/assets/sitemap.xml';

export const SiteMap = (): JSX.Element => {
    return (
        <>
            <Grid container spacing={3} paddingLeft={3} paddingRight={3}>
                <Grid item xs={12}>
                    {/* Render the sitemap content as raw HTML */}
                    <pre dangerouslySetInnerHTML={{ __html: sitemapContent }} />
                    test
                </Grid>
            </Grid>
        </>
    );
};