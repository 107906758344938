import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Grid} from '@mui/material';
import { UABanner } from '../UABanner';

export const NavBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" color={'transparent'} sx={{color: 'black'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Box + Grid - only show on xs and sm screens */}
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', sm: 'flex', md: 'none'}}}>
                        <Grid container spacing={0}>
                            <Grid item xs={4} sm={4}>
                                <img
                                    src={`${process.env.REACT_APP_STATIC_PREFIX}/ktw_inverted_logo.png`}
                                    style={{maxHeight: 68}}
                                />
                            </Grid>
                            <Grid item xs={7} sm={7} style={{marginTop: 1}}>
                                <h2
                                    style={{
                                        letterSpacing: '.1rem',
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                    }}>
                                    Knollwood Tire & Wheel
                                </h2>
                                {/*<Typography*/}
                                {/*  variant="h6"*/}
                                {/*  component="a"*/}
                                {/*  href="/"*/}
                                {/*  sx={{*/}
                                {/*    mr: 2,*/}
                                {/*    fontFamily: 'monospace',*/}
                                {/*    fontWeight: 700,*/}
                                {/*    letterSpacing: '.1rem',*/}
                                {/*    color: 'inherit',*/}
                                {/*    textDecoration: 'none',*/}
                                {/*  }}*/}
                                {/*>*/}
                                {/*  */}
                                {/*</Typography>*/}
                            </Grid>
                            <Grid item xs={1} sm={1} style={{marginTop: 8}}>
                                {/* Menu Icon on small screens */}
                                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit">
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: {xs: 'block', md: 'none'},
                                        }}>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'/'}>Home</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'/locations'}>Locations</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'/about-us'}>About Us</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'/contact-us'}>Contact Us</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'/products'}>Products</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'/services'}>Services</a>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <a href={'tel:9123830339'}>Call Us!</a>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* Box + Grid - only show on larger than small screens */}
                    <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'none', md: 'flex'}}}>
                        <Grid container spacing={0}>
                            <Grid item md={1} textAlign={'left'}>
                                <img
                                    src={`${process.env.REACT_APP_STATIC_PREFIX}/ktw_inverted_logo.png`}
                                    style={{maxHeight: 68}}
                                />
                            </Grid>
                            <Grid item md={4} style={{marginTop: 17, paddingLeft: 34}} textAlign={'center'}>
                                <Typography
                                    variant={'h5'}
                                    component="a"
                                    noWrap
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: {xs: 'none', sm: 'none', md: 'flex'},
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}>
                                    Knollwood Tire & Wheel
                                </Typography>
                            </Grid>

                            <Grid item md={7} textAlign={'right'} paddingTop={2}>
                                <Button
                                    variant="outlined"
                                    size={'small'}
                                    sx={{marginRight: 0.5}}
                                    color={'info'}
                                    href={'/locations'}>
                                    Locations
                                </Button>
                                <Button
                                    variant="outlined"
                                    size={'small'}
                                    sx={{marginRight: 0.5}}
                                    color={'info'}
                                    href={'/about-us'}>
                                    About Us
                                </Button>
                                <Button
                                    variant="outlined"
                                    size={'small'}
                                    sx={{marginRight: 0.5}}
                                    color={'info'}
                                    href={'/contact-us'}>
                                    Contact Us
                                </Button>
                                <Button
                                    variant="outlined"
                                    size={'small'}
                                    sx={{marginRight: 0.5}}
                                    color={'info'}
                                    href={'/products'}>
                                    Products
                                </Button>
                                <Button variant="outlined" size={'small'} color={'info'} href={'/services'} sx={{marginRight: 0.5}}>
                                    Services
                                </Button>
                                <Button
                                    variant="outlined"
                                    style={{backgroundColor:'#0288d1',color:'#ffffff'}}
                                    size={'small'}
                                    sx={{marginRight: 0.5}}
                                    href={'tel:9123830339'}>
                                    Call us
                                </Button>
                                {/*{pages.map((page) => (*/}
                                {/*  <Button*/}
                                {/*    key={page}*/}
                                {/*    onClick={handleCloseNavMenu}*/}
                                {/*    sx={{ my: 2, display: 'block' }}*/}
                                {/*  >*/}
                                {/*    {page}*/}
                                {/*  </Button>*/}
                                {/*))}*/}
                                {/*<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} textAlign={'right'}>*/}
                                {/*  {pages.map((page) => (*/}
                                {/*    <Button*/}
                                {/*      key={page}*/}
                                {/*      onClick={handleCloseNavMenu}*/}
                                {/*      sx={{ my: 2, display: 'block' }}*/}
                                {/*    >*/}
                                {/*      {page}*/}
                                {/*    </Button>*/}
                                {/*  ))}*/}
                                {/*</Box>*/}
                            </Grid>
                            {/*<Grid item md={3}>*/}
                            {/*  <Box sx={{ flexGrow: 0 }}>*/}
                            {/*    <Tooltip title="Open settings">*/}
                            {/*      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
                            {/*        <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />*/}
                            {/*      </IconButton>*/}
                            {/*    </Tooltip>*/}
                            {/*    <Menu*/}
                            {/*      sx={{ mt: '45px' }}*/}
                            {/*      id="menu-appbar"*/}
                            {/*      anchorEl={anchorElUser}*/}
                            {/*      anchorOrigin={{*/}
                            {/*        vertical: 'top',*/}
                            {/*        horizontal: 'right',*/}
                            {/*      }}*/}
                            {/*      keepMounted*/}
                            {/*      transformOrigin={{*/}
                            {/*        vertical: 'top',*/}
                            {/*        horizontal: 'right',*/}
                            {/*      }}*/}
                            {/*      open={Boolean(anchorElUser)}*/}
                            {/*      onClose={handleCloseUserMenu}*/}
                            {/*    >*/}
                            {/*      {settings.map((setting) => (*/}
                            {/*        <MenuItem key={setting} onClick={handleCloseUserMenu}>*/}
                            {/*          <Typography textAlign="center">{setting}</Typography>*/}
                            {/*        </MenuItem>*/}
                            {/*      ))}*/}
                            {/*    </Menu>*/}
                            {/*  </Box>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Box>

                    {/*<AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />*/}

                    {/* Icon on small screen */}
                    {/*<AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />*/}
                    {/* Business Name on small screen */}
                    {/*<Typography*/}
                    {/*  variant="h5"*/}
                    {/*  noWrap*/}
                    {/*  component="a"*/}
                    {/*  href=""*/}
                    {/*  sx={{*/}
                    {/*    mr: 2,*/}
                    {/*    display: { xs: 'flex', md: 'none' },*/}
                    {/*    flexGrow: 1,*/}
                    {/*    fontFamily: 'monospace',*/}
                    {/*    fontWeight: 700,*/}
                    {/*    letterSpacing: '.3rem',*/}
                    {/*    color: 'inherit',*/}
                    {/*    textDecoration: 'none',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  Knollwood Tire & Wheel*/}
                    {/*</Typography>*/}
                </Toolbar>
            </Container>
            <UABanner text={`Call Us \u1806 912-383-0339`} link={'tel:9123830339'} />
        </AppBar>
    );
};
