import {Button, CircularProgress, Grid, TextField} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import {useTheme} from '@mui/material/styles';
import axios from 'axios';
import {TextUsComponent} from './components/TextUs.component';

interface IContactFormValidation {
    nameFieldErrors: string[];
    emailFieldErrors: string[];
    phoneFieldErrors: string[];
    questionFieldErrors: string[];
    formIsDirty: boolean;
}

export const ContactUsPage = (): JSX.Element => {
    const [nameValue, setNameValue] = useState<string>('');
    const [emailValue, setEmailValue] = useState<string>('');
    const [phoneValue, setPhoneValue] = useState<string>('');
    const [questionValue, setQuestionValue] = useState<string>('');
    const [showLoading, setShowLoading] = useState(false);
    const [formValidation, setFormValidation] = useState<IContactFormValidation>({
        nameFieldErrors: [],
        emailFieldErrors: [],
        phoneFieldErrors: [],
        questionFieldErrors: [],
        formIsDirty: false,
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const theme = useTheme();
    const [formSuccess, setShowFormSuccess] = useState(false);

    // check / set isFormValid
    useEffect(() => {
        const asyncFunc = async () => {
            let newValue = false;
            if (nameValue !== '') {
                if (formValidation.nameFieldErrors.length === 0) {
                    if (emailValue !== '') {
                        if (formValidation.emailFieldErrors.length === 0) {
                            if (phoneValue !== '') {
                                if (formValidation.phoneFieldErrors.length === 0) {
                                    if (questionValue !== '') {
                                        if (formValidation.questionFieldErrors.length === 0) {
                                            if (formValidation.formIsDirty) {
                                                newValue = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            await setIsFormValid(newValue);
        };
        asyncFunc().then(() => {});
    }, [formValidation, nameValue, emailValue, phoneValue, questionValue]);

    const handleClickSubmit = async (): Promise<void> => {
        await setShowLoading(true);
        const body = {
            name: nameValue,
            email: emailValue,
            phone: phoneValue,
            question: questionValue,
        };
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_API_URL || ''}/contact-us`, body);
            await setShowLoading(false);
            await setShowFormSuccess(true);
        } catch (err) {
            console.log(`error posting: `);
            console.log(JSON.stringify(err));
        }
    };

    const handleValueChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        console.log(e);
        if (e.target.id === 'nameField') {
            await setNameValue(e.target.value);
        }
        if (e.target.id === 'emailField') {
            await setEmailValue(e.target.value);
        }
        if (e.target.id === 'phoneField') {
            await setPhoneValue(e.target.value);
        }
        if (e.target.id === 'questionField') {
            await setQuestionValue(e.target.value);
        }
        await validateForm(e);
    };

    const validateForm = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValidation = {...formValidation};
        newValidation.formIsDirty = true;
        if (e.target.id === 'nameField') {
            newValidation.nameFieldErrors = [];
            if (e.target.value === '') {
                newValidation.nameFieldErrors.push(`name is required`);
            }
        }
        if (e.target.id === 'emailField') {
            newValidation.emailFieldErrors = [];
            if (e.target.value === '') {
                newValidation.emailFieldErrors.push(`email is required`);
            }
        }
        if (e.target.id === 'phoneField') {
            newValidation.phoneFieldErrors = [];
            if (e.target.value === '') {
                newValidation.phoneFieldErrors.push(`phone is required`);
            }
        }
        if (e.target.id === 'questionField') {
            newValidation.questionFieldErrors = [];
            if (e.target.value === '') {
                newValidation.questionFieldErrors.push(`required`);
            }
        }

        await setFormValidation(newValidation);
    };

    return (
        <>
            <Grid container spacing={3} paddingLeft={3} paddingRight={3}>
                <Grid item xs={12} sm={6}>
                    <TextUsComponent />
                    <br />
                    <h2>Email Us</h2>
                    {!formSuccess && (
                        <>
                            <Typography color="text.secondary">
                                Please contact either one of our locations or fill out our form below and we will get
                                back to you.
                            </Typography>
                            <Box>
                                <TextField
                                    id="nameField"
                                    label="Name"
                                    variant="standard"
                                    size={'medium'}
                                    style={{width: '84%'}}
                                    value={nameValue}
                                    disabled={showLoading}
                                    onChange={handleValueChange}
                                    error={formValidation.nameFieldErrors.length > 0}
                                    helperText={formValidation.nameFieldErrors.toString()}
                                />
                                <br />
                                <TextField
                                    id="emailField"
                                    label="Email"
                                    variant="standard"
                                    size={'medium'}
                                    style={{width: '84%'}}
                                    value={emailValue}
                                    disabled={showLoading}
                                    onChange={handleValueChange}
                                    error={formValidation.emailFieldErrors.length > 0}
                                    helperText={formValidation.emailFieldErrors.toString()}
                                />
                                <br />
                                <TextField
                                    id="phoneField"
                                    label="Phone"
                                    variant="standard"
                                    size={'medium'}
                                    style={{width: '84%'}}
                                    value={phoneValue}
                                    disabled={showLoading}
                                    onChange={handleValueChange}
                                    error={formValidation.phoneFieldErrors.length > 0}
                                    helperText={formValidation.phoneFieldErrors.toString()}
                                />
                                <br />
                                <TextField
                                    id="questionField"
                                    label="Question"
                                    variant="standard"
                                    size={'medium'}
                                    style={{width: '84%'}}
                                    value={questionValue}
                                    disabled={showLoading}
                                    onChange={handleValueChange}
                                    error={formValidation.questionFieldErrors.length > 0}
                                    helperText={formValidation.questionFieldErrors.toString()}
                                />
                                <br />
                                &nbsp;
                                <br />
                                <Button
                                    variant="contained"
                                    size={'small'}
                                    onClick={() => handleClickSubmit()}
                                    disabled={showLoading || !isFormValid}>
                                    Submit
                                </Button>
                                {showLoading && <CircularProgress size={21} style={{marginLeft: 21}} />}
                            </Box>
                        </>
                    )}
                    {formSuccess && (
                        <>
                            <Typography color="text.secondary">
                                Thank You! We will get back to you as soon as possible!
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <h2>Warner Robins GA</h2>
                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                        <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                                <svg
                                    width={20}
                                    height={20}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                </svg>
                            </Box>
                        </Box>
                        <ListItemText primary="Warner Robins Phone" secondary="(478) 302-5054" />
                    </Box>
                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                        <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                                <svg
                                    width={20}
                                    height={20}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </Box>
                        </Box>
                        <ListItemText primary="Email" secondary="info@knollwoodtw.com" />
                    </Box>
                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                        <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                                <svg
                                    width={20}
                                    height={20}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Box>
                        </Box>
                        <ListItemText primary="Address" secondary="2074 Watson Blvd, Warner Robins, GA 31093" />
                    </Box>
                    <h2>Douglas GA</h2>
                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                        <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                                <svg
                                    width={20}
                                    height={20}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                </svg>
                            </Box>
                        </Box>
                        <ListItemText primary="Douglas Phone" secondary="(912) 260-0005" />
                    </Box>
                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                        <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                                <svg
                                    width={20}
                                    height={20}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </Box>
                        </Box>
                        <ListItemText primary="Email" secondary="info@knollwoodtw.com" />
                    </Box>
                    <Box component={ListItem} disableGutters width={'auto'} padding={0}>
                        <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                            <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                                <svg
                                    width={20}
                                    height={20}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor">
                                    <path
                                        fillRule="evenodd"
                                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Box>
                        </Box>
                        <ListItemText primary="Address" secondary="1122 Bowens Mill Rd SE Douglas, GA 31533" />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
