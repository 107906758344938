import styled from 'styled-components';

export const BannerContainer = styled.a`
    display: flex;
    background: ${(props) => props.theme.colors.mainAccent};
    color: ${(props) => props.theme.colors.mainFontLight};
    justify-content: center;
    text-decoration: none;
`;

export const BannerWrapper = styled.div`
    display: block;
    max-width: 96vw;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    font-size: 1rem;
    padding: 0.375rem;

    @media (min-width: 1069px) {
        display: none;
    }
`;
