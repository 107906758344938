import {Link} from 'react-router-dom';
import {Grid} from '@mui/material';
import Locations from './components/Locations/Locations';

export const LocationsPage = (): JSX.Element => {
    return (
        <>
            <Grid container spacing={3} paddingLeft={3} paddingRight={3}>
                <Grid item xs={12}>
                    <Locations />
                </Grid>
            </Grid>
        </>
    );
};
