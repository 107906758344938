import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

export const ProductCategoryPage = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  return (
    <>
      <Grid
        container
        spacing={3}
        paddingLeft={3}
        paddingRight={3}
        paddingTop={3}
      >
        <Grid item xs={12} sm={6}>
          <Box data-aos={isMd ? "fade-right" : "fade-up"}>
            <Box marginBottom={2}>
              <Typography
                variant="h2"
                color="text.primary"
                sx={{ fontWeight: 700 }}
              >
                Our{" "}
              </Typography>
              <Typography
                color={"primary"}
                component={"span"}
                variant="h2"
                fontWeight={700}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${alpha(
                    theme.palette.secondary.main,
                    0.3
                  )} 0%)`,
                }}
              >
                Products
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Typography variant="h6" component="p" color="text.secondary">
                Knollwood Tire & Wheel offers the highest quality brands of
                Vehicle Accessories with the unique ability of having hard to
                find items in stock.
                <br />
                &nbsp;
                <br />
                Tires, Wheels, Lifts, Bumpers, Performance Parts, Step Bars,
                Toolboxes, Hitches, Bed Covers, and anything else you could
                imagine for your vehicle.
              </Typography>
            </Box>
            {/*<Button variant="contained" color="primary" size="large">*/}
            {/*  Get started*/}
            {/*</Button>*/}
            <Link
              to={"/products/flatbeds"}
              style={{ color: "#0288d1", fontSize: "1.5rem" }}
            >
              Shop Flatbeds
            </Link>
            <br />
            <Link
              to={"/products/steelcraft"}
              style={{ color: "#0288d1", fontSize: "1.5rem" }}
            >
              Shop Steelcraft Automotive Bumpers
            </Link>
            <br />
            <Link
              to={"/products/oemtakeoff"}
              style={{ color: "#0288d1", fontSize: "1.5rem" }}
            >
              Shop OEM Takeoff Wheels & Wheel/Tire Packages
            </Link>
            <br />
            <Link
              to={"/products/camlocker"}
              style={{ color: "#0288d1", fontSize: "1.5rem" }}
            >
              Shop Camlocker Toolboxes
            </Link>
            <br />
            <Link
              to={"/products/rst/OEM-Chevy-RST-20x9-black-wheels-takeoff"}
              style={{ color: "#0288d1", fontSize: "1.5rem" }}
            >
              Shop RST Chevy Wheels
            </Link>
            <br />
            <Link
              to={"/spray-in-bedliners"}
              style={{ color: "#0288d1", fontSize: "1.5rem" }}
            >
              Shop Spray In Bedliners
            </Link>
            <br />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src={
              "https://txtruckaccessories.com/images/uploaded/TTA_temp_1000.png"
            }
            style={{ maxWidth: "99%" }}
            alt={"truck parts"}
          />
        </Grid>
      </Grid>
    </>
  );
};
