import styled, {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        font-family: "Roboto","Helvetica","Arial",sans-serif;
    }

    html, body {
        overflow-x: hidden;
        min-height: 100vh;
    }
`;

export const GlobalH1 = styled.h1`
    margin: 0 0 24px;
    font-weight: 700;
    font-size: 3.75rem;
    line-height: 1.2;
    letter-spacing: -0.00833em;
    color: ${(props) => props.theme.colors.mainHeaderFont};
`;

export const GlobalH1Accent = styled(GlobalH1)`
    display: inline;
    color: ${(props) => props.theme.colors.mainAccent};
    background: ${(props) => props.theme.colors.mainUnderlineBg};
`;

export const GlobalH2 = styled.h2`
    font-size: 2rem;
    font-weight: 700;
    margin: 0 0 12px;
    line-height: 1.2;
    letter-spacing: 0.00833em;
    color: ${(props) => props.theme.colors.mainHeaderFont};
`;

export const Container = styled.div`
    box-sizing: border-box;
    padding: 24px;
    margin: 0 auto;
    max-width: 1200px;
`;

export const MainParagraph = styled.p`
    margin: 8px 0 24px;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075rem;
    color: ${(props) => props.theme.colors.mainFont};
`;

export const GlobalH3 = styled.h3`
    font-size: 1.78rem;
    font-weight: 700;
    margin: 0 0 12px;
    line-height: 1.2;
    letter-spacing: 0.00833rem;
    color: ${(props) => props.theme.colors.mainHeaderFont};
`;

export default GlobalStyle;
