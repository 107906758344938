import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, TextField} from '@mui/material';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

interface ITextUsFormValidation {
    nameFieldErrors: string[];
    phoneFieldErrors: string[];
    messageFieldErrors: string[];
    formIsDirty: boolean;
}

export const TextUsComponent = (): JSX.Element => {
    const [formValidation, setFormValidation] = useState<ITextUsFormValidation>({
        nameFieldErrors: [],
        phoneFieldErrors: [],
        messageFieldErrors: [],
        formIsDirty: false,
    });
    const [formIsValid, setFormIsValid] = useState(false);
    const [messageValue, setMessageValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [showLoading, setShowLoading] = useState(false);

    const navigate = useNavigate();

    // check to see if form is valid
    useEffect(() => {
        const asyncFunc = async (): Promise<void> => {
            await setFormIsValid(false);
            if (formValidation.formIsDirty) {
                if (formValidation.nameFieldErrors.length === 0) {
                    if (nameValue !== '') {
                        if (formValidation.phoneFieldErrors.length === 0) {
                            if (phoneValue !== '') {
                                if (phoneValue.length === 14) {
                                    if (formValidation.messageFieldErrors.length === 0) {
                                        if (messageValue !== '') {
                                            await setFormIsValid(true);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        };
        asyncFunc().then(() => {});
    }, [nameValue, phoneValue, messageValue, formValidation]);

    const handleFormSubmit = async (): Promise<void> => {
        await setShowLoading(true);
        const body = {
            name: nameValue,
            phone: phoneValue,
            message: messageValue,
        };
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_API_URL || ''}/text-us`, body);
            await setShowLoading(false);
            navigate(`/text-us-success`);
        } catch (err) {
            console.log(`error posting: `);
            console.log(JSON.stringify(err));
        }
    };

    const handleValueChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (e.target.id === 'nameField') {
            await setNameValue(e.target.value);
        }
        if (e.target.id === 'phoneField') {
            const formattedNumber = formatPhoneNumber(e.target.value);
            await setPhoneValue(formattedNumber);
        }
        if (e.target.id === 'messageField') {
            await setMessageValue(e.target.value);
        }
        await validateForm(e);
    };

    const validateForm = async (e: React.ChangeEvent<HTMLInputElement>): Promise<ITextUsFormValidation> => {
        const newValidation = {...formValidation};
        newValidation.formIsDirty = true;

        if (e.target.id === 'nameField') {
            newValidation.nameFieldErrors = [];
            if (e.target.value === '') {
                newValidation.nameFieldErrors.push(`name is required`);
            }
        }

        if (e.target.id === 'phoneField') {
            newValidation.phoneFieldErrors = [];
            if (e.target.value === '') {
                newValidation.phoneFieldErrors.push(`phone is required`);
            }
        }

        if (e.target.id === 'messageField') {
            newValidation.messageFieldErrors = [];
            if (e.target.value === '') {
                newValidation.messageFieldErrors.push(`message is required`);
            }
        }

        await setFormValidation(newValidation);
        return Promise.resolve(newValidation);
    };

    const formatPhoneNumber = (currentNumber: string): string => {
        let newNumber = currentNumber.replaceAll(/[^0-9]/g, ``);
        // let newNumber = currentNumber.replaceAll(`(`, ``)
        // newNumber = newNumber.replaceAll(`)`, ``)
        // newNumber = newNumber.replaceAll(` `, ``)
        if (newNumber.length >= 4) {
            let newerNumber = `(`;
            let count = 0;
            for (const i of newNumber) {
                if (count < 10) {
                    newerNumber += i;
                }
                if (count === 2) {
                    newerNumber += `) `;
                }
                if (count === 5) {
                    if (newNumber.length >= 7) {
                        newerNumber += `-`;
                    }
                }
                count += 1;
            }
            newNumber = newerNumber;
        }
        return newNumber;
    };

    return (
        <>
            <h2>Text Us</h2>
            <Typography color="text.secondary">
                Text us anytime at 866-845-3357 or fill out the form below to send a text message. By filling out this
                form you agree that we can communicate with you via SMS. You can opt-out at any time.
            </Typography>
            <Box>
                <TextField
                    id="nameField"
                    label="Name"
                    variant="standard"
                    size={'medium'}
                    style={{width: '84%'}}
                    value={nameValue}
                    disabled={showLoading}
                    onChange={handleValueChange}
                    error={formValidation.nameFieldErrors.length > 0}
                    helperText={formValidation.nameFieldErrors.toString()}
                />
                <br />
                <TextField
                    id="phoneField"
                    label="Mobile Number"
                    variant="standard"
                    size={'medium'}
                    style={{width: '84%'}}
                    value={phoneValue}
                    disabled={showLoading}
                    onChange={handleValueChange}
                    error={formValidation.phoneFieldErrors.length > 0}
                    helperText={formValidation.phoneFieldErrors.toString()}
                />
                <br />
                <TextField
                    id="messageField"
                    label="Message"
                    variant="standard"
                    size={'medium'}
                    style={{width: '84%'}}
                    value={messageValue}
                    disabled={showLoading}
                    onChange={handleValueChange}
                    error={formValidation.messageFieldErrors.length > 0}
                    helperText={formValidation.messageFieldErrors.toString()}
                />
                <br />
                &nbsp;
                <br />
                <Button
                    variant="contained"
                    size={'small'}
                    onClick={handleFormSubmit}
                    disabled={!formIsValid || showLoading}>
                    Send Text
                    {showLoading && <CircularProgress size={13} style={{marginLeft: 8}} />}
                </Button>
            </Box>
        </>
    );
};
