import * as React from "react";
import {
  ContentWrapper,
  ProductDescriptionContainer,
  ProductInfoLine,
  ProductInfoLineKey,
  ProductInfoLineValue,
  ProductPageContainer,
} from "./styles";
import {
  I_galleryImage,
  ImageGallery,
} from "../../../common/components/Store/ImageGallery";
import { AddToCartSection } from "../../../common/components/Store/AddToCartSection";
import {
  GenericH3,
  InfoH2,
} from "../../../common/components/Global/TextHeaders";
import {
  DescriptionText,
  DescriptionUl,
} from "../../../common/components/Global/TextBlocks";

export const ProductPage: React.FC = () => {
  const images = [
    {
      focused: true,
      src: "/assets/rst-cover.png",
    },
    {
      focused: false,
      src: "/assets/rst-2.png",
    },
    {
      focused: false,
      src: "/assets/rst-3.png",
    },
    {
      focused: false,
      src: "/assets/rst-4.png",
    },
  ] as I_galleryImage[];

  return (
    <ProductPageContainer>
      <ContentWrapper>
        <ImageGallery images={images} />
        <AddToCartSection />
      </ContentWrapper>
      <ProductDescriptionContainer>
        <InfoH2>Product Description</InfoH2>
        <DescriptionText>
          This <b>20x9</b> wheel, featuring a <b>6x5.5 (6x139.7)</b> bolt
          pattern and a <b>28mm</b> offset, comes in a pristine, new takeoff
          condition with less than 5 miles on it. The hub bore measures at{" "}
          <b>78.1mm</b>.
        </DescriptionText>
        <GenericH3>Vehicle Compatibility:</GenericH3>
        <DescriptionUl>
          <li>
            <b>Avalanche/Escalade/Denali 1500</b>: Compatible with all model
            years
          </li>
          <li>
            <b>Sierra/Silverado 1500</b>: Fits models from 1999-2023 for both
            2WD & 4WD, and 1988-1998 for 4WD only.
          </li>
          <li>
            <b>Yukon/Suburban/Taho 1500</b>: Applicable to 2000-2023 models for
            both 2WD & 4WD and 1992-1999 for 4WD only.
          </li>
        </DescriptionUl>
        <GenericH3>Extras:</GenericH3>
        <DescriptionUl>
          <li>Comes with four (4) Chevy center caps.</li>
          <li>A set of 24 factory stainless capped lug nuts included</li>
          <li>
            Please note: TPMS sensors are <b>not</b> included.
          </li>
        </DescriptionUl>
        <ProductInfoLine>
          <ProductInfoLineKey>Part Number: </ProductInfoLineKey>
          <ProductInfoLineValue>84604785</ProductInfoLineValue>
        </ProductInfoLine>
        <ProductInfoLine>
          <ProductInfoLineKey>Bolt Pattern: </ProductInfoLineKey>
          <ProductInfoLineValue>6x5.5 / 6x139.7</ProductInfoLineValue>
        </ProductInfoLine>
        <ProductInfoLine>
          <ProductInfoLineKey>Wheel Size: </ProductInfoLineKey>
          <ProductInfoLineValue>20x9</ProductInfoLineValue>
        </ProductInfoLine>
        <ProductInfoLine>
          <ProductInfoLineKey>Offset: </ProductInfoLineKey>
          <ProductInfoLineValue>28mm</ProductInfoLineValue>
        </ProductInfoLine>
        <ProductInfoLine>
          <ProductInfoLineKey>Hub Bore: </ProductInfoLineKey>
          <ProductInfoLineValue>78.1mm</ProductInfoLineValue>
        </ProductInfoLine>
        <ProductInfoLine>
          <ProductInfoLineKey>Condition: </ProductInfoLineKey>
          <ProductInfoLineValue>
            NEW TAKEOFF LESS THAN 5 MILES
          </ProductInfoLineValue>
        </ProductInfoLine>
      </ProductDescriptionContainer>
    </ProductPageContainer>
  );
};
