import styled from 'styled-components';

export const InfoText = styled.p`
    margin: 0.3rem;

    @media (max-width: ${(props) => props.theme.mobileMaxWidth}) {
        display: none;
    }
`;

export const DescriptionText = styled(InfoText)`
    margin: 1rem 0.3rem;
`;

export const DescriptionUl = styled.ul`
    margin: 0 0 2rem 2rem;
    padding: 0;
`;
