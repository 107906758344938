import styled from 'styled-components';
import {MainParagraph} from "../../../globalStyles";

export const BrandContainer = styled.div`
    display: flex;
    margin: 24px auto;
    flex: 1 1 auto;

    & h2 {
        margin-left: 4px;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }
    }
`;

export const BrandNameContainer = styled.ul`
    list-style: none;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const BrandNameText = styled.li`
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 33px;
    word-break: break-word;
    margin-left: 8px;
    color: ${(props) => props.theme.colors.mainFont};

    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin: 0;
    }
`;

export const BrandLogoContainer = styled.div`
    display: block;
    position: relative;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.mainBackground};
    height: 120px;
    width: 120px;
    margin: 8px;
`;

export const BrandWrapper = styled.div`
    background: ${(props) => props.theme.colors.mainContainerBackground};
    border-radius: 3px;
    border: 1px solid ${(props) => props.theme.colors.mainDarkOutline};
    outline: ${(props) => props.theme.colors.mainDarkOutline};
    margin: 0 0 24px;
`;

export const BrandLogoImg = styled.img`
    height: auto;
    max-height: 40px;
    width: 100%;
    max-width: 100px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

export const BrandParagraph = styled(MainParagraph)`
    display: block;
    margin: 0 24px;
    padding: 8px 0;
`;

export const ProductComparisonContainer = styled.div`
    display: block;
`;

export const ProductComparisonTable = styled.table`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-radius: 3px;

    @media (max-width: 768px) {
        display: none;
    }
    
    strong {
        display: inline;
    }

    thead, tbody {
        display: contents;

        tr {
            display: contents;
            
            td:first-child {
                font-weight: 700;
            }
        }
        
        th, td {
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            min-height: 4.5rem;
            text-align: center;
            border-bottom: 1px solid #e4e5e6;
        }

        th {
            background: ${(props) => props.theme.colors.mainContainerBackground};
            z-index: 1;
            position: sticky;
            top: 5.75rem;
        }

        th:not(:first-child) {
            border-top: 1px solid #e4e5e6;
        }

        th:nth-child(1) {
            text-align: left;
        }

        th:not(:first-child), & td:not(:first-child) {
            border-left: 1px solid #e4e5e6;
        }

        td {
            padding: 1.125rem 1.25rem;
        }

        td:only-of-type {
            grid-column: span 5;
            font-size: 1.25rem;
            text-transform: uppercase;
            text-align: left;
            background-color: #f1f2f2;
            height: 4.0625rem;
            padding-left: 1.25rem;
        }
    }
    
    td:nth-child(1) {
        text-align: left;
        font-size: 1rem;
        line-height: 1.25;
    }
`;
