import styled from 'styled-components';

export const ImageGalleryContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MainPhotoWrapper = styled.a`
    display: block;
    width: 100%;
    height: 100%;
    max-width: 37.5rem;
    max-height: 37.5rem;
    margin: 0.25rem 0.5rem;
    position: relative;
    background: #ffffff;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        max-width: 95vw;
        max-height: 95vw;
        margin: 0.25rem auto;
    }
`;

export const MainPhoto = styled.img`
    display: block;
    height: 37.5rem;
    object-fit: scale-down;
    margin: 0 auto;
    max-width: 100%;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        height: 95vw;
    }
`;

export const GalleryThumbsList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        justify-content: center;
    }
`;

export const GalleryThumbContainer = styled.div`
    width: 5.75rem;
    height: 5.75rem;
    margin: 0.25rem 0.5rem;
    background: #ffffff;
    position: relative;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        width: 4.5rem;
        height: 4.5rem;
        margin: 0.25rem;
    }
`;

export const GalleryThumb = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 5.75rem;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: auto;
`;
