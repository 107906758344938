import * as React from 'react';
import * as AccStyles from './styles';
import {useEffect, useRef} from 'react';

interface I_accItem {
    header: string;
    content: string;
}

interface I_accProps {
    items: I_accItem[];
}

export interface I_accContainerProps {
    isOpen: boolean;
}

export const ProductAccordion: React.FC<I_accProps> = ({items}) => {
    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const accordionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (accordionRef.current) {
            accordionRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [activeIndex]);

    const toggleAccordion = (index: number) => {
        setActiveIndex(index);
    };

    return (
        <AccStyles.AccordionContainer>
            {items.map((item, index) => (
                <AccStyles.AccordionItemWrapper key={index}>
                    <AccStyles.AccordionHeader key={index} onClick={() => toggleAccordion(index)}>
                        {item.header}
                    </AccStyles.AccordionHeader>
                    <AccStyles.AccordionContent isOpen={activeIndex === index}>
                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                    </AccStyles.AccordionContent>
                </AccStyles.AccordionItemWrapper>
            ))}
        </AccStyles.AccordionContainer>
    );
};
