import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Typed from 'react-typed';
import Button from '@mui/material/Button';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const HeroText = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box paddingY={{xs: 0, sm: '1rem', md: '2rem'}}>
            <Box>
                <Typography
                    variant="h2"
                    color="text.primary"
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                    }}>
                    Your truck needs
                    <br />
                    some
                    <br />
                    <Typography
                        color={'primary'}
                        component={'span'}
                        variant={'inherit'}
                        sx={{
                            background: `linear-gradient(180deg, transparent 82%, ${alpha(
                                theme.palette.secondary.main,
                                0.3,
                            )} 0%)`,
                        }}>
                        <Typed
                            strings={[
                                'tires.',
                                'wheels.',
                                'attitude.',
                                'lift.',
                                'amp steps.',
                                'light bars.',
                                'character.',
                            ]}
                            typeSpeed={80}
                            loop={true}
                        />
                    </Typography>
                </Typography>
                <Typography variant="h6" component="p" color="text.secondary" sx={{fontWeight: 400}}>
                    We give your vehicle the stuff you want.
                </Typography>
                <Box
                    display="flex"
                    flexDirection={{xs: 'column', sm: 'row'}}
                    alignItems={{xs: 'stretched', sm: 'flex-start'}}
                    marginTop={4}>
                    <Button
                        component={'a'}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={!isMd}
                        href={'/contact-us'}>
                        Contact Us
                    </Button>
                    <Box marginTop={{xs: 2, sm: 0}} marginLeft={{sm: 2}} width={{xs: '100%', md: 'auto'}}>
                        <Button
                            component={'a'}
                            href={'/about-us'}
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth={!isMd}>
                            About Us
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
