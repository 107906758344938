import styled from 'styled-components';
import {I_accContainerProps} from './index';

export const AccordionContainer = styled.div`
    width: 100%;
`;

export const AccordionItemWrapper = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.mainBorderColor};
    margin: 0;
    background: ${(props) => props.theme.colors.mainContainerBackground};
`;

export const AccordionHeader = styled.div`
    background-color: ${(props) => props.theme.colors.mainContainerBackground};
    padding: 10px;
    cursor: pointer;
    font-weight: 700;
    color: ${(props) => props.theme.colors.mainFont};
    margin: 0 24px;
    font-size: 1.5rem;
`;

export const AccordionContent = styled.div<I_accContainerProps>`
    padding: 10px;
    display: ${({isOpen}: I_accContainerProps) => (isOpen ? 'block' : 'none')};
    margin: 0 24px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075rem;
    color: ${(props) => props.theme.colors.mainFont};

    & .accordion-img {
        max-width: 600px;
        margin: 8px auto;

        & img {
            height: auto;
            width: 100%;
            display: block;
        }
    }
`;

export const AccordionContentImg = styled.div``;
