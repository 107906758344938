import * as React from "react";
import { Helmet } from "react-helmet";
import * as GlobalComponents from "../../../globalStyles";
import {
  BrandContainer,
  BrandLogoContainer,
  BrandLogoImg,
  BrandNameContainer,
  BrandNameText,
  BrandParagraph,
  BrandWrapper,
} from "../../../common/components/LandingPages";
import { LargeImg } from "../../../common/components/Images";
import { ProductAccordion } from "../../../common/components/LandingPages/ProductAccordion";

const sections = [
  {
    header: "Elevation Series",
    content: `<div class='accordion-img'><img src="/assets/steelcraft-elevation-series.jpg"  alt="Pickup Truck with Steelcraft Automotive Elevation Series Front Bumper"/></div>The Elevation Series by Steelcraft Automotive is the solution for truck owners looking to enhance the look and functionality of their vehicles. This series combines a stylish grill guard with a durable flat steel construction bumper to provide maximum protection and an aggressive appearance.<br/><br/>
        One of the standout features of the Elevation Series is its ability to retain the factory tow hooks, ensuring that your truck maintains its original functionality. Additionally, this series is designed to accommodate most 20 double row lights, allowing you to customize the lighting setup on your truck to fit your specific needs.<br/><br/>
        To ensure long-lasting durability, the Elevation Series is carefully crafted with an E-coated and black powder coat finish. This coating process not only adds a sleek finish to the bumper, but also provides protection against rust and corrosion, making it ideal for all weather conditions.<br/><br/>
        Pricing begins at $1,295 for front bumpers and $645 for rear bumpers - Give us a call at <a href="tel:+19122600005">(912) 260-0005</a>  or email us at <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> for more information.
`,
  },
  {
    header: "Steelcraft HD Replacement Series",
    content: `<div class='accordion-img'><img src="/assets/steelcraft-hd-replacement-series.jpg"  alt="Pickup Truck with Steelcraft Automotive HD Replacement Series Front Bumper"/></div>The Steelcraft HD Replacement Series is a top-of-the-line bumper series designed to provide maximum protection and enhance the aesthetics of your truck. Crafted with precision engineering and rugged durability in mind, these bumpers are built to withstand the harshest off-road conditions.<br/><br/>
        One of the standout features of the Steelcraft HD Replacement Series is its integrated diamond plate and schedule 40 pipe construction. This combination not only enhances the bumper's strength, but also provides added durability to ensure long-lasting performance. Additionally, the heavy-duty mesh design offers superior protection for your truck's grille.<br/><br/>
        The Steelcraft HD Replacement Series offers a range of products to suit various truck models and preferences. Prices for the Steelcraft HD Replacement Series start at $1,195 per bumper, making it an affordable choice for those seeking top-notch quality and protection.<br/><br/>
        Pricing Begins at $1,195 for front bumpers and $595 for rear bumpers - Give us a call at <a href="tel:+19122600005">(912) 260-0005</a>  or email us at <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> for more information.
`,
  },
  {
    header: "Fortis Series",
    content: `<div class='accordion-img'><img src="/assets/steelcraft-fortis-series.jpg"  alt="Pickup Truck with Steelcraft Automotive Fortis Series Front Bumper"/></div>The Steelcraft Fortis Series is the perfect choice for truck owners seeking a sleek and modern look combined with superior protection. These bumpers are constructed with 6 gauge durable steel, ensuring durability and strength that can withstand the toughest off-road adventures.<br/><br/>
        With the Fortis Series, you can rest easy knowing your truck is equipped with a bumper that offers maximum protection. The 6 gauge steel construction provides a solid barrier against potential damage from road hazards and collisions. Whether you're tackling rough terrains or simply cruising on the highway, the Fortis Series has got you covered.<br/><br/>
        In addition to its exceptional strength and protection, the Fortis Series is designed for convenience and versatility. All Fortis bumpers come ready for shackles, allowing for easy and secure attachment of tow straps or other accessories. The bumper also features an integrated mount that can accommodate a 20" light bar, enhancing visibility during nighttime or off-road driving.<br/><br/>
        Pricing begins at $895 for front and $595 for rear - Give us a call at <a href="tel:+19122600005">(912) 260-0005</a>  or email us at <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> for more information.
`,
  },
];

export const SteelcraftPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          Truck Bumpers| Upfitter and Official Distributor of Steelcraft Bumpers
          and Accessories | Knollwood Tire & Wheel
        </title>
        <meta
          name="description"
          content="Discover the best in functional truck mods with our range of Steelcraft products at Knollwood Tire & Wheel, Georgia's leading truck accessory provider. Upgrade your truck with a Steelcraft bumper today. Visit our Georgia-based shops or contact our expert team."
        />
        <meta
          name="keywords"
          content="Utility truck bumpers, aftermarket truck bumper, aluminum bumpers, steelcraft bumpers, truck bumpers, steelcraft automotive,  knollwood Tire & Wheel, utility truck accessories."
        />
      </Helmet>
      <GlobalComponents.Container>
        <GlobalComponents.GlobalH1>
          Which Steelcraft Bumper is
          <br />
          <GlobalComponents.GlobalH1Accent>
            Right for You
          </GlobalComponents.GlobalH1Accent>
        </GlobalComponents.GlobalH1>
        <LargeImg>
          <img src="/assets/steelcraft-bumper.png" alt="Steelcraft Bumper" />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          SteelCraft offers a wide range of available series bumpers that are
          designed to fit various truck models. Whether you have an F-450 or
          Tacoma, SteelCraft has a bumper series specifically made for your
          vehicle. These bumpers are crafted with heavy-duty plate steel and
          feature a black powder coat finish for enhanced durability and a
          sleek, modern look.
          <br />
          <br />
          The bolt-on installation makes it easy to upgrade your truck with a
          SteelCraft bumper, providing you with heavy-duty protection and a
          stylish upgrade. With their commitment to using high-grade materials
          and advanced manufacturing processes, SteelCraft ensures that their
          available series bumpers will not only enhance the appearance of your
          truck but also provide reliable protection for years to come.
        </GlobalComponents.MainParagraph>
        <BrandWrapper>
          <BrandContainer>
            <BrandLogoContainer>
              <BrandLogoImg
                src="/assets/steelcraft-logo-huge.png"
                alt="Logo for Steelcraft Automotive"
              />
            </BrandLogoContainer>
            <BrandNameContainer>
              <BrandNameText>
                Steelcraft Bumper Series Information
              </BrandNameText>
            </BrandNameContainer>
          </BrandContainer>
          <BrandParagraph>
            Steelcraft offers a wide range of bumper series to suit different
            needs and preferences. While Steelcraft offers a multitude of
            series, we focus on HD front/rear replacement bumpers, Elevation
            Series, and Fortis Series. The SteelCraft HD Replacement Series
            offers a sleek alternative to stock bumpers, giving your vehicle a
            customized appearance. The Elevation Series combines a modern look
            with heavy-duty protection, perfect for those who want both style
            and functionality. Finally, the Fortis Series is designed for
            heavy-duty use, featuring a removable front panel and tow hook for
            added convenience. Want to learn more in depth? Take a look below.
          </BrandParagraph>
          <ProductAccordion items={sections} />
        </BrandWrapper>
        <GlobalComponents.GlobalH3>
          History of Steelcraft Automotive
        </GlobalComponents.GlobalH3>
        <LargeImg>
          <img
            src="/assets/steelcraft-close.png"
            alt="Close up of Steelcraft Bumper"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          Steelcraft Automotive, established by Alex Villareal in 1998, has
          emerged as a pioneering force in the automotive industry. Beginning
          with grille guards and bull bars, their durable and stylish
          accessories quickly gained acclaim, leading to expansion into side
          steps, running boards, and roof racks. Over the last 10 years,
          Steelcraft Automotive has focused on the development of aftermarket
          utility truck bumpers.
          <br />
          <br />
          Steelcraft Automotive is the best choice for anyone looking for
          durable, high-quality bumpers at good prices. Their bumpers are
          constructed with precision engineering and made from heavy-duty plate
          steel, ensuring maximum protection for your vehicle. Their product
          warranty is inclusive of a 2-year manufacturer warranty.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Do Steelcraft bumpers work with factory sensors & cameras?
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          Simply put, yes. Steelcraft bumpers are one of the only aftermarket
          truck bumpers that are designed to retain all factory sensors & camera
          locations on your light or heavy duty truck.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Why pick Steelcraft Over the Rest?
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          When it comes to choosing a bumper for your truck, there are many
          options on the market. However, if you're looking for superior build
          quality, longer warranty coverage, and extensive installation support,
          look no further than SteelCraft **all of which are backed by our
          hometown support, inventory, and experience.
          <br />
          <br />
          One of the key reasons to choose SteelCraft over other competitors in
          the bumper industry is their commitment to providing the highest level
          of build quality. With their Fortis Series, SteelCraft offers a bumper
          that is constructed using 6 gauge steel, providing maximum protection
          against potential damage from road hazards and collisions. This level
          of durability ensures that your truck is equipped with a bumper that
          can withstand even the toughest terrains.
          <br />
          <br />
          Another advantage of choosing SteelCraft is their longer warranty
          coverage. While other competitors may offer limited warranty periods,
          SteelCraft stands behind their products with a comprehensive warranty.
          This means that you can have peace of mind knowing that your
          investment is protected. In addition to their build quality and
          warranty coverage, SteelCraft also provides extensive installation
          support. They understand that proper installation is essential for
          optimal functionality of the bumper and offer detailed installation
          instructions to ensure that you can install your bumper with ease.
          <br />
          <br />
          Furthermore, SteelCraft utilizes advanced E-coating technology in
          their manufacturing process. This innovative coating provides
          exceptional corrosion resistance, ensuring that your bumper stays in
          top condition for years to come. Additionally, SteelCraft uses
          schedule 40 pipe in the HD Series bumpers, providing added durability
          and strength.
          <br />
          <br />
          Overall, when it comes to choosing a bumper for your truck, SteelCraft
          stands out from the rest. Their commitment to superior build quality,
          longer warranty coverage, extensive installation support, and
          innovative technologies like E-coating and schedule 40 pipes make them
          the ideal choice for truck owners who want the best protection for
          their vehicle.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          The #1 Reason to Buy Steelcraft Front & Rear Bumpers
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          The #1 reason customers should choose SteelCraft Automotive Bumpers is
          their unmatched combination of high quality and affordable prices. As
          one of the top aftermarket bumper brands, SteelCraft has built a
          reputation for producing top-notch products that provide superior
          protection for your truck.
          <br />
          <br />
          SteelCraft takes pride in using only the highest quality materials and
          employing advanced manufacturing techniques to ensure the durability
          and reliability of their bumpers. Each bumper goes through a rigorous
          testing process, guaranteeing that it will withstand the toughest
          conditions and protect your vehicle from unforeseen hazards.
          <br />
          <br />
          What sets SteelCraft apart from the competition is their ability to
          offer bumpers for the newest truck models up to 180 days earlier. That
          means you can get the latest bumper designs for your vehicle sooner,
          giving it a fresh and modern look without having to wait. With
          SteelCraft, you don't have to compromise on style or functionality.
          <br />
          <br />
          In addition to their exceptional quality and early availability for
          new truck models, SteelCraft bumpers are also known for their
          affordability. They offer a budget-friendly option without sacrificing
          performance or style, so you can protect your truck without breaking
          the bank. When it comes to front and rear bumpers, SteelCraft is the
          top choice for customers who want high quality, affordable prices, and
          the latest designs for their trucks. Trust SteelCraft to enhance your
          vehicle's appearance and provide the ultimate protection.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Aftermarket Truck Bumper Sales & Installation in Georgia
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          If you're looking for high-quality bumpers for your truck in the
          SouthEast U.S., Knollwood Tire & Wheel offers the best pricing and
          installation on Steelcraft bumpers. We offer all bumpers for trucks,
          but prioritize Steelcraft for utility use. We quote, install and
          handle the entire process of bumper installation at our locations in
          Warner Robins and Douglas, Georgia.
          <br />
          <br />
          To purchase and install a Steelcraft bumper in Georgia, simply contact
          us here or through phone at either location. To reach the Douglas
          store please call <a href="tel:+14783025054">(478) 302-5054</a>. To
          reach the Warner Robins location please call{" "}
          <a href="tel:+19122600005">(912) 260-0005</a>. We will walk you
          through the available options, assist with customizations, provide
          shipping and delivery options, and schedule installation with one of
          our professional bed installers. With Steelcraft Bumpers through
          Knollwood Tire & Wheel, you can be sure that you are getting a
          dependable, high-quality replacement bumper or brush guard that will
          meet your needs for years to come.
        </GlobalComponents.MainParagraph>
      </GlobalComponents.Container>
    </>
  );
};
