import styled from 'styled-components';

export const AddToCartSectionContainer = styled.div`
    margin: 1rem;
    max-width: 32rem;
    display: flex;
    flex-direction: column;

    & > h1 {
        line-height: 3.1rem;
    }

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        margin: 1rem auto;

        & > h1 {
            line-height: unset;
        }
    }
`;

export const AddToCartPricing = styled.span`
    display: block;
    color: ${({theme}) => theme.colors.mainFont};
    font-size: 2rem;
    font-weight: 450;
    margin: 0.8rem 0.4rem;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        font-size: 1.3rem;
    }
`;

export const AddToCartButton = styled.button`
    display: block;
    background: ${({theme}) => theme.colors.secondaryAccent};
    color: ${({theme}) => theme.colors.mainFontLight};
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    margin: 1rem 0 0;
    font-weight: 700;
    border-radius: 0.188rem;
    border: 0;
    outline: 0;
    grid-row: span 1;
    cursor: pointer;
    letter-spacing: 0.1rem;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        margin: 0.5rem 0;
    }
`;

export const AddToCartButtonMain = styled(AddToCartButton)`
    background: ${({theme}) => theme.colors.mainAccent};
`;

export const AddToCartValueProps = styled.ul`
    display: block;
    font-size: 1.3rem;
    margin: 0 0 0 2rem;
    padding: 0;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        font-size: 1.1rem;
        grid-row: span 2;
    }
`;

export const ValueProp = styled.li`
    margin: 0.25rem 0;
`;
