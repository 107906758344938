import styled from 'styled-components';

export const ProductPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    margin: 0.25rem auto;
    padding: 0 0.5rem;
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-around;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        flex-direction: column;
    }
`;

export const ProductDescriptionContainer = styled.div`
    margin: 4rem 0;

    @media (max-width: ${({theme}) => theme.mobileMaxWidth}) {
        margin: 1.5rem 0;
    }
`;

export const ProductInfoLine = styled.div``;

export const ProductInfoLineKey = styled.span`
    text-transform: uppercase;
    font-weight: 700;
`;

export const ProductInfoLineValue = styled.span``;
