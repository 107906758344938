import {DefaultTheme} from 'styled-components';

const theme: DefaultTheme = {
    colors: {
        mainBackground: '#ffffff',
        mainBackgroundHover: '#ffffff',
        mainBorderColor: 'rgba(0, 0, 0, 0.12)',
        mainContainerBackground: '#f6f6f6',
        mainLightContainerBackground: '#f3f3f3',
        mainDarkOutline: 'rgba(0, 0, 0, 0.06)',
        mainDarkButtonBack: '#1e1e1e',
        mainDarkButtonHover: '#4a4a4a',
        mainDarkBorderHover: '#6e6666',
        mainAccent: '#1976d2',
        secondaryAccent: '#6a6a6a',
        mainUnderlineBg: 'linear-gradient(180deg, transparent 82%, rgba(156, 39, 176, 0.3) 0%)',
        mainFont: 'rgba(0, 0, 0, 0.8)',
        mainHeaderFont: '#000000',
        mainFontLight: '#f3f3f3',
        mainFontDark: '#797b83',
        mainFontHover: '#ffffff',
        mainStepLight: '#c3c3c3',
        mainStepLightBorder: '#a3a3a3',
        mainStepLightFont: '#5a5a5a',
        divider: '#444c56',
    },
    fonts: {
        mainFont: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    },
    mainShadow: '0 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 4px 0 rgba(0, 0, 0, 0.19)',
    lightShadow: '0 0 3px 0 rgba(0, 0, 0, 0.2)',
    mobileMaxWidth: '768px',
    desktopMinWidth: '769px',
};

export {theme};
