import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { NavBar } from "./common/components/NavBar";
import Footer from "./common/components/Footer";
import { LocationsPage } from "./pages/LocationsPage";
import { ContactUsPage } from "./pages/ContactUsPage";
import { ProductCategoryPage } from "./pages/ProductCategoryPage";
import { ServicesPage } from "./pages/ServicesPage";
import { SiteMap } from "./pages/SiteMap";

// google analytics
import ReactGA from "react-ga4";
import { TextUsSuccessPage } from "./pages/TextUsSuccessPage/TextUsSuccessPage";
import { DouglasRetailStorePage } from "./pages/DouglasRetailStorePage";
import { FlatbedPage } from "./pages/LandingPages/FlatbedPage";
import { SteelcraftPage } from "./pages/LandingPages/SteelcraftPage";
import { CamlockerPage } from "./pages/LandingPages/CamlockerPage";
import { TakeoffPage } from "./pages/LandingPages/TakeoffPage";
import { ProductPage } from "./pages/LandingPages/RSTWheelsPage";
import { GlobalHelmet } from "./GlobalHelmet";
import { ThemeProvider } from "styled-components";
import { theme } from "./Theme";
import GlobalStyle from "./globalStyles";
import { SprayBedlinersPage } from "./pages/LandingPages/SprayBedlinersPage";

const TRACKING_ID = process.env.REACT_APP_GANALYTICS || "G-N3JCDBKPBV"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <GlobalHelmet />
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />}>
              <Route index element={<HomePage />} />
              {/*<Route path="about-us" element={<ServicesPage />}>*/}
              {/*  <Route path=":teamId" element={<Team />} />*/}
              {/*  <Route path="new" element={<NewTeamForm />} />*/}
              {/*  <Route index element={<LeagueStandings />} />*/}
              {/*</Route>*/}
            </Route>
            <Route path={"/about-us"} element={<AboutUsPage />} />
            <Route
              path={"/douglas-retail-store"}
              element={<DouglasRetailStorePage />}
            />
            <Route path={"/locations"} element={<LocationsPage />} />
            <Route path={"/contact-us"} element={<ContactUsPage />} />
            <Route path={"/products"} element={<ProductCategoryPage />} />
            <Route
              path={"/spray-in-bedliners"}
              element={<SprayBedlinersPage />}
            />
            <Route path={"/products/flatbeds"} element={<FlatbedPage />} />
            <Route path={"/products/steelcraft"} element={<SteelcraftPage />} />
            <Route path={"/products/oemtakeoff"} element={<TakeoffPage />} />
            <Route path={"/products/camlocker"} element={<CamlockerPage />} />
            <Route
              path={"/products/rst/OEM-Chevy-RST-20x9-black-wheels-takeoff"}
              element={<ProductPage />}
            />
            <Route path={"/services"} element={<ServicesPage />} />
            <Route path={"/text-us-success"} element={<TextUsSuccessPage />} />
            <Route path={"/site-map"} element={<SiteMap />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
