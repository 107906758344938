import * as React from "react";
import { InfoH1 } from "../../Global/TextHeaders";
import {
  AddToCartButton,
  AddToCartButtonMain,
  AddToCartPricing,
  AddToCartSectionContainer,
  AddToCartValueProps,
  ValueProp,
} from "./styles";
import { AtcModal } from "./AtcModal";

export const AddToCartSection: React.FC = () => {
  const [showForm, setShowForm] = React.useState(false);

  const handleCTAClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowForm(true);
  };

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowForm(false);
  };

  return (
    <AddToCartSectionContainer>
      <AtcModal showForm={showForm} closeForm={handleCloseClick} />
      <InfoH1>Factory OEM Chevy RST Wheels Rims & New Tires 20x9 Black</InfoH1>
      <AddToCartPricing>
        <b>$995</b> Shipped Without Tires
      </AddToCartPricing>
      <AddToCartPricing>
        <b>$1595</b> With New Goodyar AT Tires
      </AddToCartPricing>
      <AddToCartValueProps>
        <ValueProp>Free Shipping</ValueProp>
        <ValueProp>Free Lug Nuts</ValueProp>
        <ValueProp>Ships in 2-3 days!</ValueProp>
      </AddToCartValueProps>
      <AddToCartButtonMain onClick={handleCTAClick}>
        Get your Quote
      </AddToCartButtonMain>
      <AddToCartButton
        onClick={() => (window.location.href = "tel:+19122600005")}
      >
        Contact Us To Buy Now
      </AddToCartButton>
    </AddToCartSectionContainer>
  );
};
