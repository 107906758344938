/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {alpha, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import {useNavigate} from 'react-router-dom';

const mock = [
    {
        title: 'Products',
        subtitle:
            'Knollwood Tire & Wheel offers a full line of all vehicle accessories while also offering years of knowledge and expertise to guide you during your vehicle build experience.',
        icon: (
            <svg
                height={36}
                width={36}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"
                />
            </svg>
        ),
        url: '/products',
    },
    {
        title: 'Locations',
        subtitle:
            "Knollwood Tire & Wheel has multiple locations to better serve our customer's needs. Stop by and meet the friendly and knowledgeable staff and let us show you your vehicle's potential.",
        icon: (
            <svg
                height={36}
                width={36}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                />
            </svg>
        ),
        url: '/locations',
    },
    {
        title: 'Services',
        subtitle:
            'Knollwood Tire & Wheel offers a full service experience with technicians specialized in all areas of vehicle accessories. Anything from adding a trailer hitch to a full front end rebuild, we have you covered.',
        icon: (
            <svg
                height={36}
                width={36}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"
                />
            </svg>
        ),
        url: '/services',
    },
];

const Services = (): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Box>
            {/*<Box marginBottom={4}>*/}
            {/*  <Box marginBottom={2}>*/}
            {/*    <Typography*/}
            {/*      variant="h4"*/}
            {/*      color="text.primary"*/}
            {/*      align={'center'}*/}
            {/*      gutterBottom*/}
            {/*      sx={{*/}
            {/*        fontWeight: 700,*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      Build accessible React apps with speed*/}
            {/*    </Typography>*/}
            {/*    <Typography*/}
            {/*      variant="h6"*/}
            {/*      component="p"*/}
            {/*      color="text.secondary"*/}
            {/*      sx={{ fontWeight: 400 }}*/}
            {/*      align={'center'}*/}
            {/*    >*/}
            {/*      Build a beautiful, modern website with flexible, fully customizable,*/}
            {/*      atomic MUI components.*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <Grid container spacing={2}>
                {mock.map((item, i) => (
                    <Grid item xs={12} md={4} key={i}>
                        <Box
                            width={1}
                            height={1}
                            data-aos={'fade-up'}
                            data-aos-delay={i * 100}
                            data-aos-offset={100}
                            data-aos-duration={600}>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <Box
                                    component={Avatar}
                                    width={60}
                                    height={60}
                                    marginBottom={2}
                                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                                    color={theme.palette.primary.main}
                                    onClick={() => navigate(item.url)}>
                                    {item.icon}
                                </Box>
                                <Typography
                                    variant={'h6'}
                                    gutterBottom
                                    sx={{fontWeight: 500}}
                                    align={'center'}
                                    onClick={() => navigate(item.url)}>
                                    {item.title}
                                </Typography>
                                <Typography align={'center'} color="text.secondary">
                                    {item.subtitle}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Services;
