import * as React from "react";
import { Helmet } from "react-helmet";
import * as GlobalComponents from "../../../globalStyles";
import {
  BrandContainer,
  BrandLogoContainer,
  BrandLogoImg,
  BrandNameContainer,
  BrandNameText,
  BrandParagraph,
  BrandWrapper,
} from "../../../common/components/LandingPages";
import { LargeImg } from "../../../common/components/Images";
import { ProductAccordion } from "../../../common/components/LandingPages/ProductAccordion";

const sections = [
  {
    header: "CamLocker Classic Truck Box",
    content: `
        Bedrock's The CamLocker Classic Truck Box is a high-quality storage solution designed specifically for pickup trucks. Made from heavy-duty aluminum, the CamLocker Classic Truck Box is lightweight, yet sturdy enough to withstand the rigors of daily use. The toolbox is available in multiple sizes, allowing you to choose the one that best fits your truck's dimensions and your storage needs.
        <br>
        <br>
        THE CLASSIC: Measures 14” deep (lid included) x 20” wide. Available in lengths from 54” to 71” and comes in a 6” profile with a low profile option at 3”. With a unique notching system of UN (Universal Notch) and FN (Full Notch) notches, we can ensure the proper fit for each year, make and model. Available finishes in polished aluminum, matte black (MB) or gloss black (GB).`,
  },
  {
    header: "CamLocker King Size Truck Box",
    content: `
        The CamLocker King Size Truck Box is the ultimate storage solution for those who have a larger collection of tools and equipment. It features all the same standard features as THE CLASSIC aluminum tool box, but with deeper capacity which results in over 25% more secure storage space. 
        <br>
        <br>
        THE KING: Measures 19” deep (lid included) x 20” wide and is available in lengths from 54” to 71”. The KING is available with the same profile options as the Classic CamLocker with the Regular profile at 6” above the rail and the LP at just 3”. If needed, the same unique notching system can be applied to ensure proper fit.`,
  },
  {
    header: "CamLocker Deep & Wide Truck Box + Extra Deep & Wide",
    content: `The CamLocker Deep & Wide Truck Box is the perfect solution for truck owners in need of ample storage space. With its deep and wide design, this truck box offers plenty of room to securely store your tools, equipment, and other belongings.
        <br>
        <br>
        The Deep & Wide adds over 4.5" to the width and 2" to the depth above the Classic box. As a result, truck owners receive 30% more storage area than the standard CamLocker box. This allows greater flexibility with power tools and safety equipment. 
        <br>
        <br>
        THE DEEP & WIDE: Measures 16” deep (lid included) x 24.5” wide and is currently available in the 71” length with other sizes soon to come. The same profile options are available at 6” above the truck bed-rail for the Regular and 3” for the LP. With the added width and depth, the UN (Universal Notch) and FN (Full Notch) notch systems are sure to provide the proper fit. Polished aluminum, matte black or gloss black are all available on the DW too.
        <br>
        <br>
        Looking for larger? We also offer in partnership with CamLocker the Xtra Deep & Wide which adds 4.5" to the width and 5" to the depth of the standard box which extends the aluminum tool box to 24.5" wide and 19" deep. This is currently the largest Camlocker Toolbox available for purchase!`,
  },
  {
    header: "CamLocker Slimline Truck Box",
    content: `
        The CamLocker Slimline Truck Box is a sleek and compact storage solution for your truck. With its slim design, it fits perfectly into any truck bed, maximizing storage space without sacrificing accessibility. The Slimline caters to the truck owner who can't sacrifice bed space but still needs a lockable protection toolbox for their truck.
        <br>
        <br>
        The CamLocker Slimline Truck Box also boasts a stylish and sleek appearance. Its low-profile design adds a touch of sophistication to your truck while providing the storage space you need.
        <br>
        <br>
        THE SLIMLINE: Measures 14” deep (lid included) on the Standard or 19” deep (lid included) on the King. Either 14” or 18” wide on any lengths/series from 54” to 71”. As with their other lines, both profiles, Regular and Low Profiles are available. While the Slimline models are designed for shorter bed lengths the unique notching system is still available to accommodate appropriate fit. As with all CamLocker boxes, the Slimline has a polished aluminum, matte or gloss black finish options.`,
  },
  {
    header: "CamLocker Side Mount Box",
    content: `
        The CamLocker Side Mount Box is a versatile storage solution for your tools and equipment. Designed to be mounted on the side of your truck bed, this toolbox offers convenient access to your tools while maximizing your bed space.
        <br>
        <br>
        Measuring 12" deep x 12" wide for the outer dimensions, the CamLocker Side Mount Box provides ample storage capacity for all your essentials. It is constructed from the same high quality aluminum to provide you a storage tool box that works.
        <br>
        <br>
        The CamLocker Side Mount Box is available in different models, including the Matte Black Aluminum SMB60MB and the Heavy-Duty Polished Aluminum models. Each model is designed to meet different needs and preferences, offering a variety of features and finishes.`,
  },
];

export const CamlockerPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          CamLocker Truck Boxes | Upfitter and Official Distributor of CamLocker
          Toolboxes | Knollwood Tire & Wheel
        </title>
        <meta
          name="description"
          content="Discover the best in functional truck mods with our range of Bedrock flatbeds at Knollwood Tire & Wheel, Georgia's leading truck accessory provider. Upgrade your truck with a Bedrock flatbed today and experience the difference in performance and capability. Visit our Georgia-based shops or contact our expert team for advice tailored to your needs."
        />
        <meta
          name="keywords"
          content="Utility truck toolboxes, truck boxes, sleek toolboxes, truck storage, CamLocker, knollwood Tire & Wheel, utility truck accessories."
        />
      </Helmet>
      <GlobalComponents.Container>
        <GlobalComponents.GlobalH1>
          Aftermarket
          <br />
          <GlobalComponents.GlobalH1Accent>
            Truck Tool Boxes
          </GlobalComponents.GlobalH1Accent>
        </GlobalComponents.GlobalH1>
        <LargeImg>
          <img
            src="/assets/camlocker-hero.png"
            alt="Camlocker Toolbox in a pickup truck"
          />
        </LargeImg>
        <GlobalComponents.MainParagraph>
          When it comes to aftermarket truck tool boxes, CamLocker Tool Boxes
          stand out as one of the best options on the market. Offering a range
          of high-quality aluminum tool boxes and accessories, CamLocker
          provides truck owners with durable storage solutions that are built to
          last.
          <br />
          <br />
          CamLocker tool boxes are constructed from high-quality aluminum and
          built right here in the United States. This ensures that the tool
          boxes are not only sturdy enough to withstand the rigors of everyday
          use but also easy to install and handle.
          <br />
          <br />
          One of the standout features of CamLocker tool boxes is the use of
          aluminum in their construction. This material enhances the overall
          durability and longevity of the tool boxes, making them resistant to
          rust and corrosion.
          <br />
          <br />
          CamLocker tool boxes are equipped with hefty chrome plated SecureLock
          Twist Handles, which are an exclusive option to authentic CamLocker
          products. This secured latch system along with the insulated lids keep
          your tools and belongings protected from the elements.
          <br />
          <br />
          CamLocker tool boxes are designed for a variety of applications,
          including law enforcement, fire and rescue, K-9 transportation, and
          military use. This speaks to the versatility and reliability of these
          tool boxes, making them the perfect choice for various professional
          and personal needs.
          <br />
          <br />
          As a proud and active reseller of CamLocker Toolboxes for all
          commercial and recreational truck builds, give us a call today at{" "}
          <a href="tel:+19122600005">(912) 260-0005</a> or email us at{" "}
          <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a> to
          inquire about our in-stock inventory of CamLocker tool boxes and
          experience our exceptional customer service.
        </GlobalComponents.MainParagraph>
        <BrandWrapper>
          <BrandContainer>
            <BrandLogoContainer>
              <BrandLogoImg
                src="/assets/camlocker-logo.svg"
                alt="Logo for Camlocker"
              />
            </BrandLogoContainer>
            <BrandNameContainer>
              <BrandNameText>CamLocker Tool Boxes</BrandNameText>
            </BrandNameContainer>
          </BrandContainer>
          <BrandParagraph>
            CamLocker tool boxes are the ultimate choice for individuals and
            professionals seeking a durable and reliable storage solution for
            their tools and belongings. These tool boxes are constructed with
            high-quality aluminum, known for its strength and lightweight
            properties, ensuring both durability and ease of use.
            <br />
            The use of aluminum in the construction enhances the overall
            longevity of the tool boxes, making them resistant to rust and
            corrosion. Equipped with hefty chrome plated SecureLock Twist
            Handles, these tool boxes offer not only style and sleekness but
            also enhanced security.
            <br />
            Designed for a variety of applications, including law enforcement,
            fire and rescue, K-9 transportation, and military use, CamLocker
            tool boxes are the go-to option for those who value versatility and
            reliability in their storage needs. Whether for personal or
            professional use, CamLocker tool boxes guarantee the protection and
            organization of your tools and belongings.{" "}
          </BrandParagraph>
          <ProductAccordion items={sections} />
        </BrandWrapper>
        <GlobalComponents.GlobalH3>
          What are CamLocker Truck Boxes
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          CamLocker truck boxes are known for their high-quality construction
          and durability. They come in several different types, each with its
          own unique features.
          <br />
          <br />
          The first type is the standard CamLocker under rail truck box, which
          is available in an all aluminum construction. These boxes are
          available in various dimensions, allowing you to choose one that fits
          your specific needs. They come with chrome plated T-handles and offer
          secure storage for your tools and equipment.
          <br />
          <br />
          Another type is the crossover CamLocker truck box, which is designed
          to fit over the bed of your truck. These boxes feature a rail system
          for easy installation and removal. For those looking for additional
          organization, CamLocker offers truck boxes with tool trays. These
          boxes have a sliding tool tray organizer, allowing you to easily
          access your tools and keep them organized.
        </GlobalComponents.MainParagraph>
        <LargeImg>
          <img
            src="/assets/camlocker-full-out.png"
            alt="Camlocker Toolbox in a door"
          />
        </LargeImg>
        <GlobalComponents.GlobalH3>
          Is CamLocker a good brand?
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          CamLocker is widely regarded as a reputable and high-quality brand in
          the industry. Their products have earned a strong reputation for their
          durability, functionality, and innovative features. Evidence from
          customer reviews and industry experts consistently highlights the
          superior quality of CamLocker toolboxes.
          <br />
          <br />
          CamLocker is considered a good brand in their commitment to using
          premium materials and production in the United States. Each CamLocker
          comes with a 3 year structural warranty and a commitment of ultimate
          quality of American-made products that you'd expect from your truck.
          <br />
          <br />
          Another factor contributing to CamLocker's reputation as a good brand
          is their attention to detail and functionality. Their tool boxes are
          designed with features such as sliding tool tray organizers and tool
          trays, enhancing organization and ease of use. The inclusion of hefty
          chrome plated T-handles and secure locking systems further demonstrate
          their commitment to providing secure storage solutions for valuable
          tools and equipment.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          Standard CamLocker features:
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          <ul>
            <li>3 Year Structural Warranty</li>
            <li>CamLock locking mechanism keeps tools locked securely</li>
            <li>Key-lockable chrome-plated hefty carbon steel T-Handles</li>
            <li>Heavy Duty .063 gauge polished Aluminum welded construction</li>
            <li>T-Handles open from either side for easy access</li>
            <li>Full-length continuous Stainless Steel hinges</li>
            <li>Insulated lids outperform all others</li>
            <li>Weather-sealed lids</li>
            <li>Comes with removable, sliding tool trays</li>
            <li>Completely carpeted throughout sides and floor</li>
            <li>Floor carpeting is removable for easy cleaning</li>
            <li>
              Also available are side mount boxes, emergency vehicle boxes, UTV
              boxes, trailer boxes, dog boxes, and more
            </li>
          </ul>
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH2>
          Types of CamLock Toolboxes
        </GlobalComponents.GlobalH2>
        <GlobalComponents.MainParagraph>
          CamLocker products are differentiated through the <b>box depth</b>,{" "}
          <b>finish</b>, <b>lid profile</b>, and <b>rail style</b>. This allows
          each toolbox to be unique to the truck and its intended purpose. Some
          of the most common models of Camlocker Toolboxes can be found below.
        </GlobalComponents.MainParagraph>
        <GlobalComponents.GlobalH3>
          CamLocker Classic Truck Box
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          The CamLocker Classic Truck Box is a high-quality storage solution
          designed specifically for pickup trucks. Made from heavy-duty
          aluminum, the CamLocker Classic Truck Box is lightweight, yet sturdy
          enough to withstand the rigors of daily use. The toolbox is available
          in multiple sizes, allowing you to choose the one that best fits your
          truck's dimensions and your storage needs.
          <br />
          <br />
          THE CLASSIC: Measures 14” deep (lid included) x 20” wide. Available in
          lengths from 54” to 71” and comes in a 6” profile with a low profile
          option at 3”. With a unique notching system of UN (Universal Notch)
          and FN (Full Notch) notches, we can ensure the proper fit for each
          year, make and model. Available finishes in polished aluminum, matte
          black (MB) or gloss black (GB).
        </GlobalComponents.MainParagraph>
        <LargeImg>
          <img
            src="/assets/camlocker-full.png"
            alt="Camlocker Toolbox on cardboard boxes"
          />
        </LargeImg>
        <GlobalComponents.GlobalH3>
          CamLocker In Stock - Lowest Prices
        </GlobalComponents.GlobalH3>
        <GlobalComponents.MainParagraph>
          At Knollwood Tire & Wheel, we are proud to offer CamLocker toolboxes
          that are in-stock and ready to ship <b>same day</b>. We provide expert
          service to help ensure you're selecting the right tool box to fit your
          vehicle while shopping at the best prices. All CamLocker Aluminum Tool
          Boxes can be installed at any of our KTW locations in Georgia.
          <br />
          <br />
          To learn more, give us a call today at{" "}
          <a href="tel:+19122600005">(912) 260-0005</a> or email us at{" "}
          <a href="mailto:info@knollwoodtw.com">info@knollwoodtw.com</a>.
        </GlobalComponents.MainParagraph>
      </GlobalComponents.Container>
    </>
  );
};
