import {Grid} from '@mui/material';

export const TextUsSuccessPage = (): JSX.Element => {
    // const theme = useTheme();
    // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    //   defaultMatches: true,
    // });
    return (
        <>
            <Grid container spacing={3} paddingLeft={3} paddingRight={3}>
                <Grid item xs={12}>
                    <h2>We got your text!</h2>
                    Please check your phone and let's finish this conversation via text messaging. Thanks!
                </Grid>
            </Grid>
        </>
    );
};
