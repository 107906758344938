import styled from 'styled-components';

export const GenericH1 = styled.h1`
    font-size: 4.5rem;
`;

export const HeroH1 = styled(GenericH1)`
    width: 75%;
    text-align: center;
    margin: 0 auto;
    max-width: 1100px;
`;

export const InfoH1 = styled(GenericH1)`
    font-size: 3rem;

    @media (max-width: ${(props) => props.theme.mobileMaxWidth}) {
        font-size: 1.6rem;
        margin: 0.5rem 0.3rem;
    }
`;

export const GenericH2 = styled.h2``;

export const HeroH2 = styled(GenericH2)`
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    margin: 1rem auto 2rem;
`;

export const InfoH2 = styled(GenericH2)`
    font-size: 2rem;
`;

export const GenericH3 = styled.h3`
    font-size: 1.2rem;
    margin: 0.5rem 0;
`;
